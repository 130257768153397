//
// home.scss
//

.home-page-banner {
  position: relative;
  min-height: 65vh;
  background-color: hsl(0deg 0% 0%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff !important;

  @media (max-width: 576px) {
    min-height: 30vh;
  }
  @media (max-width: 1246px) {
    min-height: 70vh;
  }

  > .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 154, 120, 0.8);
  }
  .bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    li {
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.1);
      bottom: -50px;
      animation: square 20s infinite;
      transition-timing-function: linear;
      &:nth-child(1) {
        left: 10%;
      }
      &:nth-child(2) {
        left: 20%;
        width: 120px;
        height: 120px;
        animation-delay: 2s;
        animation-duration: 17s;
      }
      &:nth-child(3) {
        left: 25%;
        animation-delay: 4s;
      }
      &:nth-child(4) {
        left: 40%;
        width: 80px;
        height: 80px;
        animation-duration: 22s;
      }
      &:nth-child(5) {
        left: 70%;
        width: 90px;
        height: 90px;
      }
      &:nth-child(6) {
        left: 70%;
        width: 120px;
        height: 120px;
        animation-delay: 3s;
      }
      &:nth-child(7) {
        left: 32%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }
      &:nth-child(8) {
        left: 55%;
        width: 80px;
        height: 80px;
        animation-delay: 15s;
        animation-duration: 40s;
      }
      &:nth-child(9) {
        left: 25%;
        width: 50px;
        height: 50px;
        animation-delay: 2s;
        animation-duration: 40s;
      }
      &:nth-child(10) {
        left: 90%;
        width: 140px;
        height: 140px;
        animation-delay: 11s;
      }
    }

    @keyframes square {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-1000px) rotate(600deg);
      }
    }
  }
  &__header {
    padding: 4rem 0;

    @media (max-width: 1200px) {
      padding: 4rem;
    }
    @media (max-width: 1000px) {
      padding: 2rem;
    }

    @media (max-width: 576px) {
      padding: 1rem;
    }
  }
  &__role {
    grid-template-columns: repeat(auto-fit, 425px);
    gap: 1rem;
    width: 100%;
    grid-gap: 20px;

    @media (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, 330px);
    }
    @media (max-width: 375px) {
      grid-template-columns: repeat(auto-fit, 330px);
    }
  }
  &__navbar {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    justify-content: space-around;
    align-items: center;
  }

  &__logo {
    > img {
      width: 12rem;
      height: auto;

      @media (max-width: 576px) {
        width: 10rem;
      }
    }
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
  }

  &__title {
    > img {
      width: 35rem;
      height: auto;

      @media (max-width: 768px) {
        width: 25rem;
      }

      @media (max-width: 576px) {
        width: 15rem;
      }
    }
  }

  &__desc {
    @media (max-width: 768px) {
      padding: 0 2rem;
    }
  }

  &__hamburger-container {
    position: fixed;
    right: 10%;
    z-index: 1000;
  }

  &__hamburger {
    > i {
      font-size: 1.5rem;
      color: $yellowMain;
    }
  }
}

.home-page-banner__desc {
  white-space: pre-line;
}

.home-page-second {
  min-height: 45vh;
  padding: 3rem 2rem 2rem 2rem;
  background-color: black;

  @media (max-width: 768px) {
    padding: 3rem 2rem 2rem 2rem;
  }

  &__ad-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
    }

    > img {
      width: 13rem;
      height: auto;

      @media (max-width: 1200px) {
        width: 13rem;
      }

      @media (max-width: 768px) {
        width: 10rem;
      }
    }
  }
}

.home-page-third {
  min-height: 25vh;
  padding: 2rem;
  background-color: #1a1a1a;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .social-icon {
    width: 30px;
    height: 30px;
  }
}

.home-page-fourth-card {
  // width: 100vw;
  min-height: 40vh;
  // padding: 2rem 2rem 1rem 2rem;
  padding: 2rem 0 1rem 0;
  position: relative;
  background-color: white;

  @media (max-width: 768px) {
    padding: 0rem 0rem 0rem 0rem;
  }
}

.card-section-slider {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1240px) {
  .card-section-slider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
@media (min-width: 1435px) {
  .card-section-slider {
    justify-content: space-between;
  }
}

.card-section-slider .img-logo-homepage {
  // position: absolute;
  // right: 328px;
}

img.logo-middle-section-new {
  width: 100%;
}

img.logo-middle-section {
  width: 320px;
}

.card-slider-component {
  // position: relative;
  // margin-left: auto;
  // margin-right: auto;
  // left: 0;
  // right: 0;
  // text-align: center;
  // padding: 0 170px 0 170px;
  // position: relative;
  // right: 328px;
}

.home-page-fourth {
  min-height: 40vh;
  max-width: 100%;
  padding: 2rem 2rem 1rem 2rem;

  @media (max-width: 768px) {
    padding: 2rem 2rem 1rem 2rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 2rem;

    > img {
      width: 35rem;
      height: auto;

      @media (max-width: 1500px) {
        width: 27rem;
      }

      @media (max-width: 1200px) {
        width: 27rem;
      }

      @media (max-width: 768px) {
        width: 17rem;
      }
    }
  }
}

.home-page-footer {
  //max-height: 20vh;
  padding: 2rem 4rem;
  background-color: white;

  &__logo {
    width: 10rem;
    height: auto;
  }
}

body[data-layout-mode='dark'] {
  .home-page-fourth__desc {
    color: #ced4da !important;
  }

  .home-page-footer * {
    color: #313533 !important;
  }
}

.home-page-banner__navbar .nav-item {
  padding-top: 0.8rem;
  margin: 0px 0.5rem;
  width: auto !important;
  min-width: 100px;
}

@keyframes learnMore {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.home-page-banner__learn {
  animation: learnMore 1s infinite alternate;
}
.footer-section {
  display: flex;
  flex-direction: row;
}
.home-page {
  overflow-x: hidden;
}
