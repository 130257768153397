.change-password-form-container {
  .reset-password-btn {
    width: 200px;
  }
  .card-body {
    // padding: 3rem 10rem;
    @media (max-width: 700px) {
      padding: 0;
      align-items: center;
    }
  }
}
