body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #1a1a1a;
  /* width: 100vw; */
  font-family: -apple-system, BlinkMacSystemFont, 'ProductSans', 'RSU', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'ProductSans', 'RSU',
    'Courier New', monospace;
}

/* RSU */
/* Regular Weight */
@font-face {
  font-family: 'RSU';
  src: local('RSU'), url('./assets/fonts/RSU_Regular.ttf') format('truetype');
}

/* Bold Weight */
@font-face {
  font-family: 'RSU';
  src: local('RSU'), url('./assets/fonts/RSU_BOLD.ttf') format('truetype');
  font-weight: bold;
}

/* light Weight */
@font-face {
  font-family: 'RSU';
  src: local('RSU'), url('./assets/fonts/RSU_light.ttf') format('truetype');
  font-weight: lighter;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

/* Scrollbar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar:hover {
  cursor: pointer;
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
  /* background-color: rgb(123, 123, 123); */
  cursor: pointer;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.bbb {
  border: 1px solid black;
}
.center-x-y {
  display: flex;
  justify-content: center;
  align-items: center;
}
