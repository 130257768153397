@keyframes changewidth {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.enable-img {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  padding: 0 20px;
  height: 69px;
  align-items: center;
  // border: 1px solid black;
  @media only screen and (max-width: 1090px) {
    padding: 0 15px;
  }

  .disable-img {
    visibility: hidden;
  }

  .text-link {
    color: #156978;
    font-size: 18px;
  }

  .nav-items {
    list-style-type: none;
    text-decoration: none;
    margin: 0 53px;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .nav-text {
    font-size: 18px;
    font-weight: 500;
  }

  .nav-text:hover {
    font-size: 24px;
    font-weight: 700;
  }

  .nav-text-hover {
    font-size: 24px;
    font-weight: 700;
  }
}

.header-container-mobile {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  align-items: center;
  .notification-mobile {
    margin-left: auto;
    order: 2;
  }
}
.text-header-alert {
  position: absolute;
  color: white;
  background: #ff6174;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  z-index: 12;
  margin-left: 5px;
  top: 15px;
}

.resposive-header-icon {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
