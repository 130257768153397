.progress-bar-container {
  display: flex;
  align-items: center;
  .indicator {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    // left: rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
    align-items: center;
  }
  .progress-number {
    font-size: 1.5em;
    font-weight: bold;
  }
  .progress-bars {
    margin: 0px;
  }
  svg {
    width: 50px;
    height: 50px;
    margin: 1em;
    margin-left: 1.5rem;
  }

  span {
    position: absolute;
    font-size: 2em;
    font-weight: bold;
    z-index: 10;
    top: 10%;
    left: 10%;
    transform: translate(-50%, -60%);
    width: 100%;
    white-space: nowrap;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bg {
    fill: none;
    stroke-width: 10px;
    stroke: var(--background);
  }

  .meter {
    fill: none;
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: 360;
    stroke-dashoffset: var(--percent);
    stroke: var(--color);
    animation: progress var(--timer) ease-out;
  }

  @keyframes progress {
    from {
      stroke-dashoffset: 360;
    }
    to {
      stroke-dashoffset: var(--percent);
    }
  }
}
