.create-marketer-job-campaign-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: auto;

  .card-container {
    position: relative;
    height: max-content;
    min-height: 300px;
    width: 100%;
    max-width: 50rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;

    form {
      width: 80%;
      margin: auto;

      @media only screen and (max-width: 540px) {
        width: 100%;
      }
    }

    .profile-container {
      text-align: center;

      .image-profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .radio-container {
      padding: 1rem;
      margin-top: 2rem;
      border-radius: 1rem;
      background: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      flex-wrap: wrap;
      // p:nth-child(2),
      // p:nth-child(3) {
      //   font-size: 1.2em;
      //   font-weight: 500;
      // }

      .social-grid-container {
        display: grid;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(3, 1fr);
        // gap: 0.5rem;
        justify-items: center;

        @media only screen and (max-width: 540px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .content-grid-container {
        display: grid;
        grid-template-rows: repeat(auto, 1fr);
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        justify-items: center;
      }
    }

    .btn-create {
      background: #3baf96;
      border-radius: 1.5rem;
      padding: 0.5rem 1rem;
      min-width: 8rem;
      font-size: 1.2em;
      font-weight: bold;
      outline: none;
      border: none;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      margin: 0;
      transition: all 0.1s ease-in-out;
      text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
        0px -5px 35px rgba(255, 255, 255, 0.3);
      color: #ffffff;

      &:hover {
        transform: scale(1.05) !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
          rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }

      &:active {
        transform: scale(0.95) !important;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
    }
  }
}
