body[data-layout-mode='dark'] {
    background-color: $gray-dark-200;
    color: #a3958f;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #a3958f;
    }
}

.float-left {
    float: left;
}

.font-weight-bold {
    font-weight: bold;
}

.inline-block {
    display: inline-block;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.black-background {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
}
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.border-image {
    border: solid white;
    border-width: 3px;
}
