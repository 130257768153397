.influencer-job-detail-container {
  .job-detail-collapse {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 4px 8px 2px #00000040;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .pointer {
    cursor: pointer;
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .influencer-card {
    width: 100%;

    @media (min-width: 768px) {
      padding: 20px;
    }
  }
  hr {
    width: 100%;
  }
  .influencer-timeline-card {
    border: none;
    width: 100%;
    margin: auto;
  }
  .influencer-post-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    justify-content: space-around;
    .header-post-title {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .image-post-influencer-control {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
      max-height: 250px;
      max-width: 250px;
      overflow: hidden;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-right: 50px;
      .image-post-influencer {
        height: auto;
        width: 100%;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        filter: alpha(opacity=0);
      }
      @media (max-width: 1186px) {
        margin-right: 0;
        max-height: 200px;
      }
    }
    .influencer-post-video-content {
      display: flex;
      align-items: flex-start;
      @media (max-width: 1186px) {
        align-items: center;
        flex-direction: column;
      }
    }
    .message-post-influencer {
      width: 100%;
    }
    @media (max-width: 1600px) {
      .message-post-influencer {
        width: 100%;
        margin-top: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
  }
  .header-job-title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #a983fb;
    @media screen and (max-width: 375px) {
      font-size: 18px !important;
    }
  }
  .new-job-container {
    display: flex;
    flex-direction: column;
  }
  .title-head {
    font-size: 16px;
    font-weight: 600;
    color: #a983fb;
    margin: 10px 0 0 0;
  }
  .detail-text {
    line-height: 1.2;
    font-size: 16px;
    font-weight: 400;
    color: rgb(73, 80, 87);
    padding-bottom: 10px;
    word-break: break-all;
  }
  .influencer-post-count-first {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .count-control {
      width: 20%;
      display: flex;

      .icon {
        width: 25px;
        height: 25px;
      }
      .text {
        margin-left: 15px;
      }
    }
  }
  .influencer-post-count-second {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;

    .count-control {
      display: flex;
      width: 20%;

      .icon {
        width: 25px;
        height: 25px;
      }
      .text {
        margin-left: 15px;
      }
    }
  }
  .influencer-impression-count {
    display: flex;
    align-items: center;
    @media (max-width: 1186px) {
      display: grid;
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(4, 1fr);
      gap: 0.2rem;
    }
    .icon {
      margin-left: 15px;
      width: 25px;
      height: 25px;
    }
    .text {
      margin-left: 15px;
    }
  }

  .statistics-control {
    display: flex;
    justify-content: center;
    margin: 40px 20px;
    @media (max-width: 768px) {
      margin: 5px 5px;
    }
    .inner-statistics-control {
      display: flex;
      width: 100%;
      justify-content: space-around;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 992px) {
        width: 70%;
      }
      .icon {
        width: 40px;
        height: 40px;
      }
      p {
        font-size: 20px;
        width: 90%;
      }
    }
  }
  .time-line-div {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-top: 10px;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  .icon-group-time-line {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 420px) {
      align-items: center;
    }
    @media screen and (max-width: 375px) {
      padding: 0 5px !important;
    }

    h5 {
      @media screen and (max-width: 375px) {
        font-size: 0.8rem !important;
      }
    }
    span {
      @media screen and (max-width: 375px) {
        font-size: 0.8rem !important;
      }
    }
  }
  .timeline-container {
    padding: 0 0 10px 0px;
    position: relative;
    background-color: inherit;
    width: 100%;
    left: 10.5%;
    @media (max-width: 1550px) {
      left: 10%;
    }
    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: #dddddd;
      border: 4px solid #888585;
      top: 15px;
      border-radius: 50%;
      z-index: 1;
      left: -12%;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      @media (max-width: 600px) {
        left: -12.5%;
      }
      @media (max-width: 420px) {
        left: -13.5%;
      }
    }

    &::before {
      content: ' ';
      height: 0;
      position: absolute;
      top: 22px;
      width: 0;
      z-index: 1;
      left: -10px;
      border: medium solid rgba(60, 64, 67, 0.3);
      border-width: 10px 10px 10px 0;
      border-color: transparent rgba(60, 64, 67, 0.3) transparent transparent;
    }
  }
  .timeline {
    padding: 0;
    position: relative;
    max-width: 1200px;
    margin: 0;
    width: 90%;
    /* background-color: red; */

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      background-color: #888585;
      top: 0;
      bottom: 0;
      margin-left: -3px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
  }
  .timeline-content {
    padding: 0;
    position: relative;
    min-height: 120px;
    margin-bottom: 0;
    justify-content: space-evenly;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(73, 80, 87, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 420px) {
      flex-direction: column;
    }
  }
  .card-date-selected-label {
    color: #21603e;
    padding: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    cursor: no-drop;
    line-height: 29px;

    @media screen and (max-width: 375px) {
      font-size: 0.8rem !important;
    }
  }
  .card-date-container-selected {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    width: 120px;
    height: auto;
    cursor: no-drop;
    background: white;
    border: 2px solid #21603e;
  }
  @media only screen and (max-width: 600px) {
    .card-date-container-selected {
      width: 100%;
    }
  }
}

.header-job-detail {
  padding: 0 2vh 0 2vh;
  width: 100%;
  @media (min-width: 770px) {
    width: 80%;
    padding: 0 2vh 0 2vh;
    .product-detail {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #a3a3a3;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  @media (min-width: 992px) {
    width: 80%;
    padding: 0 12vh 0 12vh;
    .product-detail {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #a3a3a3;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
    }
  }
  @media (max-width: 660px) {
    .product-detail {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #a3a3a3;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .brand-name-title {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      text-align: left;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .brand-name-title {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
  }
  .cancel-reason {
    color: rgb(163, 163, 163);
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .product-detail {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #a3a3a3;
  }
  .company-name {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    color: #a3a3a3;
    line-height: 30px;
  }
  .social-type {
    max-width: 400px;
    font-size: 16px;
    color: #a3a3a3;
  }
  .content-type {
    max-width: 400px;
    font-size: 16px;
    color: #a3a3a3;
    line-height: 10px;
  }
  .influencer-name {
    max-width: 400px;
    font-size: 16px;
    font-weight: 400;
    color: #a3a3a3;
  }
  .group-social {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
  }
  .column-group {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 650px) {
      min-width: 0px;
    }
  }
  .row-social-media {
    display: flex;
    flex-direction: row;
  }
  .influencer-name {
    max-width: 400px;
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
  }
  .social-type {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
  }
  .content-type {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    line-height: 10px;
  }
  .price {
    max-width: 400px;
    font-size: 18px;
    color: #ffff;
  }
}
.image-carousel-container {
  width: 100%;
  .image-preview {
    object-fit: revert;
    border-radius: 8px;
    @media (max-width: 660px) {
      height: 200px;
    }
  }
  @media (max-width: 660px) {
    width: 300px;
  }
  @media (max-width: 400px) {
    width: 200px;
  }
}
