.search-bar-clear-container {
  .block-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }
  input::placeholder {
    color: white;
  }
  .clear-button {
    height: 34px;
    padding: 0 30px;
    background-color: black;
    border-radius: 15px;
    border: none;
    color: white;
  }
  .clear-button-detail {
    height: 34px;
    padding: 0 30px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    border-radius: 8px;
    border: none;
    color: white;
    box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
    @media only screen and (max-width: 650px) {
      margin-top: 10px;
    }
  }
  .container {
    position: relative;
    margin-right: 16px;
  }
  @media only screen and (max-width: 650px) {
    .clear-button {
      height: 34px;
      width: 100%;
      padding: 0 30px;
      background: linear-gradient(104deg, #6b99ca, #6bc6b3);
      border-radius: 8px;
      border: none;
      color: white;
    }
    .block-container {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
input.form-control.text-color-primary.search-bar-white::placeholder {
  color: gray !important;
}
button.clear-button.search-bar-white {
  background-color: white;
  color: black;
}
@media only screen and (max-width: 650px) {
  button.clear-button.search-bar-white {
    height: 34px;
    width: 100%;
    padding: 0 30px;
    background: white !important;
    border-radius: 8px;
    border: none;
    color: black;
  }
}
