.job-detail-collapse1-container {
  width: 60%;
  margin: auto;
  margin-top: 10px;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  // transform: translateY(-45px);
  .job-detail-collapse {
    // padding: 1rem 1.5rem;
  }
  .influencer-select-modal {
    // height: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
    text-align: center;
    p {
      font-size: 14px;
    }
    .influencer-image-container {
      display: flex;
      justify-content: center;
      .image-control {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        margin: 0 10px;
        width: 100px;
        flex-wrap: wrap;
        word-break: break-all;
        text-align: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin: 10px 10px;
        }
      }
    }
  }
  .influencer-card {
    padding: 1rem 1.5rem;

    .text-editor-displays {
      font-family: sans-serif;
      p,
      li {
        font-size: 16px !important;
        margin-bottom: 0;
        line-height: 1.5rem;
      }
      li::marker {
        font-size: 25px;
      }
      .ql-font-serif {
        font-family: serif !important;
      }
      .ql-font-monospace {
        font-family: monospace !important;
      }
      // strong {
      //   font-size: 28px;
      // }
      .ql-size-large {
        font-size: 35px;
      }
      .ql-size-huge {
        font-size: 46px;
      }
    }
    .button-job {
      display: flex;
      margin: 15px 0;
      justify-content: center;

      @media only screen and (max-width: 450px) {
        transform: scale(0.85);
      }
    }
    .header-job-title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #a983fb;

      @media screen and (max-width: 375px) {
        font-size: 18px !important;
      }
    }
    .icon-group-time-line {
      @media screen and (max-width: 375px) {
        padding: 0 5px !important;
      }
      h5 {
        @media screen and (max-width: 375px) {
          font-size: 0.8rem !important;
        }
      }
      span {
        @media screen and (max-width: 375px) {
          font-size: 0.8rem !important;
        }
      }
      .card-date-selected-label {
        @media screen and (max-width: 375px) {
          font-size: 0.8rem !important;
        }
      }
    }
  }
  .job-detail-btn {
    font-size: 1.2em;
    // font-weight: bold;
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 15px;
    margin-top: -10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: transparent;
  }
  .border-radius-card-close {
    border-radius: 25px;
  }
  .border-radius-card-open {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .job-detail-flex-container {
    position: relative;
    padding: 0;
    text-align: center;
    background: linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    // background: linear-gradient(299.46deg, #ff94df 15.75%, #44c0fa 74.37%);
    .work-icon-container {
      @media only screen and (max-width: 680px) {
        display: none;
      }
    }
    .icon-check {
      position: absolute;
      background-color: green;
      border-radius: 50%;
      width: 15px;
      color: white;
      position: absolute;
      height: 15px;
      border: 1px solid green;
      text-align: center;
      line-height: 23px;
      left: -1rem;
      transform: translate(30px, 0px);
    }
    .job-detail-number {
      background: white;
      width: 20px;
      height: 20px;
      padding: auto;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 50%;
      color: black;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .job-detail-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 20px;
      align-items: center;
      justify-content: space-between;
      position: relative;

      text-align: center;
      cursor: pointer;
      width: 100%;

      @media only screen and (max-width: 550px) {
        margin-left: 10px;
        // bottom: 0.5rem;
      }
    }
  }
}
