//
// _modal.scss
//

body[data-layout-mode='dark'] {
    // modal
    .modal-header,
    .modal-footer {
        border-color: $gray-dark-300;
    }
}

.modal-text-center {
    text-align: center !important;
}

.grid-upload-banner {
    border-radius: 5px;
    font-weight: $font-weight-medium;
    padding: 10px 20px;
    font-size: 0.8rem;
}

.helpful {
    background-color: $gray-dark-800;
    border-radius: 0.5rem;

    i {
        cursor: pointer;
    }
}

// .fa-thumbs-up {
//     padding: 0.5rem;
//     border-radius: 50%;
//     background-color: #56ACF0 !important;
// }

// .fa-thumbs-up:hover {
//     filter: brightness(.8);
// }

.fa-thumbs-down {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #FD625E !important;
}

.fa-thumbs-down:hover {
    filter: brightness(.8);
}