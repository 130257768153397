.privacy-policy-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 1em;

  background: url('../../../assets/images/influencer-posting-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0, 154, 120, 0.8);
  }

  .bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    li {
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.1);
      bottom: -50px;
      animation: square 20s infinite;
      transition-timing-function: linear;
      &:nth-child(1) {
        left: 10%;
      }
      &:nth-child(2) {
        left: 20%;
        width: 120px;
        height: 120px;
        animation-delay: 2s;
        animation-duration: 17s;
      }
      &:nth-child(3) {
        left: 25%;
        animation-delay: 4s;
      }
      &:nth-child(4) {
        left: 40%;
        width: 80px;
        height: 80px;
        animation-duration: 22s;
      }
      &:nth-child(5) {
        left: 70%;
        width: 90px;
        height: 90px;
      }
      &:nth-child(6) {
        left: 70%;
        width: 120px;
        height: 120px;
        animation-delay: 3s;
      }
      &:nth-child(7) {
        left: 32%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }
      &:nth-child(8) {
        left: 55%;
        width: 80px;
        height: 80px;
        animation-delay: 15s;
        animation-duration: 40s;
      }
      &:nth-child(9) {
        left: 25%;
        width: 50px;
        height: 50px;
        animation-delay: 2s;
        animation-duration: 40s;
      }
      &:nth-child(10) {
        left: 90%;
        width: 140px;
        height: 140px;
        animation-delay: 11s;
      }
    }

    @keyframes square {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-1000px) rotate(600deg);
      }
    }
  }

  .card {
    width: 100%;
    height: 40rem;
    max-width: 50rem;
    margin: 2.5rem auto;
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    z-index: 2;

    .header {
      font-size: 1.5em;
      font-weight: bold;
    }

    .content-container {
      overflow: auto;
      padding: 0.5rem;
      .content {
        // text-indent: 2rem;
      }
    }
  }
}
