.product-name-left {
  max-width: px;
  font-size: 14px;
  font-weight: 600;
  color: #a3a3a3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 5px;

  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
}
