.create-marketer-public-container-mock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;
  // .card-public-container {
  //   width: 550px;
  //   border-radius: 1rem;

  //   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  //   background-color: #fbfaff;
  //   border: solid 5px transparent;

  //   background-origin: border-box;
  //   background-clip: content-box, border-box;
  //   box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
  //     rgba(0, 0, 0, 0.24) 0px 1px 2px;

  //   @media (max-width: 450px) {
  //     width: 100%;
  //     // display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .card-public-label {
  //     display: flex;
  //     width: 100%;
  //     gap: 1rem;
  //     justify-content: center;
  //     align-items: center;
  //     flex-wrap: wrap;

  //     .card-public-min-max {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       gap: 1rem;

  //       label {
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
  .radio-container {
    display: inline-flex;
    gap: 1rem;
    width: 60%;
    // border: 1px solid;
    // height: 50px;
    @media (max-width: 800px) {
      width: 80%;
    }
    @media (max-width: 650px) {
      width: 90%;
    }
    @media (max-width: 415px) {
      width: 100%;
      gap: 2rem;
    }
    // .border-left {
    //   position: relative;
    //   margin-left: 1rem;
    //   padding-left: 1rem;

    //   @media (max-width: 400px) {
    //     position: none;
    //     margin-left: 0rem;
    //     padding-left: 0rem;

    //     &::after {
    //       content: '';
    //       position: absolute;
    //       border-left: 1px solid #000000;
    //       top: 50%;
    //       left: 0%;
    //       height: 50%;
    //       margin-top: auto;
    //       margin-bottom: auto;
    //       transform: translateY(-50%);
    //     }
    //   }
    //   &::after {
    //     content: '';
    //     position: absolute;
    //     border-left: 1px solid #000000;
    //     top: 50%;
    //     left: 0%;
    //     height: 50%;
    //     margin-top: auto;
    //     margin-bottom: auto;
    //     transform: translateY(-50%);
    //   }
    // }

    .radio-input {
      text-align: center;
      position: relative;
      width: 100%;
      cursor: pointer;
      display: inline-block;
      transition: all 0.1s ease-in;

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95);
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.image-full {
          width: 85px;
          height: 85px;
        }

        &.image-icon {
          width: 100px;
          height: 100px;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 10px;
          padding: 0.5rem;
        }
      }

      label {
        color: #000000;
        margin-top: 90px;
        cursor: pointer;
        font-weight: normal;
        font-size: 1rem;
        white-space: nowrap;
      }

      i {
        position: absolute;
        font-size: 0.8em;
        padding: 0.2rem;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid #f1eee9;
        color: #f1eee9;
        right: 10px;
        top: 30%;
        transform: translate(0, -30%);
      }

      input {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        &:checked ~ i {
          background-color: #b4ff9f;
          color: #ffffff;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 16px;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  button {
    background-color: #3db5ff;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 0.5rem 1rem;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
  }

  button:hover {
    background-color: #219deb;
  }

  button:active {
    background-color: #219deb;
  }
}
