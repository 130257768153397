.mock-job-requirement {
  .mock-job-requirement-card {
    width: 500px;
    margin: 100px auto;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;

    label {
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
      color: #333;
    }

    input,
    select {
      margin-left: 20px;
    }

    input[type='text'],
    select {
      width: 50%;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 18px;
      padding: 0 10px;
      background-color: #f2f2f2;
      transition: all 0.3s ease;
    }

    input[type='text']:focus,
    select:focus {
      border-color: #4caf50;
      outline: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
      margin: 0 5px;
      vertical-align: middle;
    }

    button[type='submit'] {
      width: 120px;
      height: 40px;
      background-color: #4caf50;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
    }

    button[type='submit']:hover {
      background-color: #3e8e41;
    }

    .radio-group {
      display: flex;
      flex-wrap: wrap;
    }

    .radio-column {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .radio-option {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      cursor: pointer;
    }

    .radio-option input[type='radio'] {
      margin-right: 8px;
    }

    .radio-option input[type='radio']:focus + span {
      outline: 2px solid #4d90fe;
    }

    .radio-option input[type='radio']:checked + span {
      font-weight: bold;
    }
  }
}
