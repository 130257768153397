.admin-side-bar {
  .item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .notification-icon {
    color: white;
    background: red;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
  }
}
