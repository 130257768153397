.social-card {
  .alertAttitude {
    color: red;
    display: flex;
    position: static;
    border: black sold 2px;
    top: 100px;
  }
  min-height: 250px;
  margin-bottom: 0px;
  min-width: 165px;
  width: 165px;
  background-color: white;
  padding: 10px;
  border-radius: 8% !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

  @media (max-width: 400px) {
    width: 150px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .btn-pending {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #ffed01;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }
  .btn-approved {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #3baf96;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }
  .btn-rejected {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #d10000;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }

  &-img {
    position: relative;

    .logo {
      position: absolute;
      width: 25px;
      height: 25px;
      padding: 0;
      border-radius: 50%;
      background-color: #ffffff;
    }

    .profile {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }

  &-detail {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > span {
      font-size: 1rem;
    }

    .name {
      font-weight: bold;
      width: 160px;
      height: 1rem;
      margin-bottom: 6px;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .icon {
      color: #ffe400;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .price {
      font-weight: lighter;
      font-size: 1rem;
    }
  }
}

.social-card-facebook {
  .alertAttitude {
    color: red;
    display: flex;
    position: static;
    border: black sold 2px;
    top: 100px;
  }
  min-height: 250px;
  margin-bottom: 0px;
  min-width: 165px;
  width: 165px;
  background-color: white;
  padding: 10px;
  border-radius: 8% !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

  @media (max-width: 400px) {
    width: 150px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .btn-pending {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #ffed01;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }
  .btn-approved {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #3baf96;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }
  .btn-rejected {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    border: none;
    background: white;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #d10000;
      color: #ffffff;
      margin-right: 0.4rem;
    }
  }

  &-img {
    position: relative;

    .logo {
      position: absolute;
      width: 25px;
      height: 25px;
      padding: 0;
      border-radius: 50%;
      background-color: #ffffff;
    }

    .profile {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    &:hover {
      // transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }

  &-detail {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > span {
      font-size: 1rem;
    }

    .name {
      font-weight: bold;
      width: 160px;
      height: 1rem;
      margin-bottom: 6px;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .icon {
      color: #ffe400;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .price {
      font-weight: lighter;
      font-size: 1rem;
    }
  }
}
