.mock-job-requirement-table {
  
  table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
    width: 400px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    background-color: #4caf50;
    color: white;
  }
}
