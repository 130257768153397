.mock-influ-publish-job {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  .check-match-button {
    display: flex;
    gap: 0rem;
    border: '1px solid black';
    div {
      font-size: 14px;
      font-weight: bold;
    }
    .active {
      // background-color: #2a512b;
      border-bottom: 3px solid white;
    }
  }
  .date-max-width {
    max-width: 150px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  .select-influencer-image-control {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    flex-wrap: wrap;
    max-width: 600px;
    .select-influencer-image {
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 10px;
      }
      .active {
        border: #4caf50 solid 6px;
        width: 60px !important;
        height: 60px !important;
      }
    }
  }

  .central-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    border: 2px solid #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    box-shadow: 0px 0px 4px 0px #727272;
    border-radius: 10px;
    // padding: 20px;
    margin: 20px;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 0px 4px #666161);
      scale: calc(100%);
    }

    .data-control {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
      p {
        margin: 5px 10px;
        // font-size: 20px;
        font-weight: bold;
        color: rgb(68, 64, 64);
        font-size: 18px;
      }
    }
    .marketer-image-control {
      display: flex;
      font-size: 24px;
      width: 100%;
      align-items: center;
      justify-content: center;
      .marketer-image {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin: 5px 20px 20px 10px;
        }
      }
    }

    .influencer-image {
      background-color: linear-gradient(
        180deg,
        #9cefee 0%,
        #c2f2ce 48.96%,
        #f7df81 100%
      );
      display: flex;
      justify-content: center;
      // align-items: center;
      flex-wrap: wrap;
      .influencer-image-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
  }

  .central-card h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }

  .central-card p {
    font-size: 1.2em;
    color: #666;
  }
}
.filter-sort-public {
  display: flex;
  justify-content: center;
  width: 40%;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

.card-job-public {
  height: auto;
  max-width: 350px;
  width: 100%;
  font-size: 14px;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
  .card {
    border: 'none';
    padding: 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #a3a3a3;
    .card-body {
      padding: 0;
    }
    .card-title {
      padding: 10px 20px 0px 30px;
      // margin-bottom: 4px;

      @media only screen and (max-width: 450px) {
        padding: 10px 15px 0px 15px;
      }
    }
  }
  &:hover {
    // box-shadow: 0px 4px 4px 0px #a3a3a3;
    // filter: drop-shadow(0px 4px 4px #a3a3a3);
    border-radius: 16px;
    // scale: calc(100%);
  }

  .brand-name {
    max-width: 400px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
  .product-name {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: gray;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 370px) {
      max-width: 140px;
    }
  }
  .company-name {
    max-width: 400px;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    color: #a3a3a3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;

    @media only screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
  .date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 8px;
    font-size: 14px;

    span {
      color: gray;
    }

    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
  .brand-name-title {
    max-width: 400px;
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    color: #495057;
    letter-spacing: 0em;
    text-align: left;
  }
  .influencer-name {
    max-width: 98px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    white-space: normal;
    color: gray;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: 650px) {
      font-size: 14px;
      width: 80px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .social-type {
    max-width: 400px;
    font-size: 14px;
    color: gray;
    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
  .content-type {
    max-width: 400px;
    font-size: 14px;
    color: gray;
    line-height: 10px;
    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
  .cancel-reason {
    color: rgb(163, 163, 163);
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .price-suggest {
    min-width: 90px;
    max-width: 400px;
    font-size: 14px;
    color: #495057;
    margin-right: '10px';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: '1px solid';
    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  }
  .status-image {
    position: absolute;
    width: 285px;
    height: 190px;
  }

  .search-filter-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 650px) {
      .clear-button {
        width: 98%;
        margin-right: 15px;
        padding: 0 15px;
        box-shadow: none;
        background: linear-gradient(104deg, #6b99ca, #6bc6b3);
        height: 34px;
        color: white;
        border-radius: 8px;
        border: none;
      }
    }
  }
  .group-social {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 90%;
    // margin-left: 1rem;
    @media only screen and (max-width: 450px) {
      width: 90%;
      margin: 0;
    }
  }
  .column-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    // margin-left: 1rem;
    // min-width: 90px;
    @media only screen and (max-width: 650px) {
      min-width: 0px;
    }
  }
  .row-social-media {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  @media only screen and (max-width: 650px) {
    .search-filter-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media only screen and (max-width: 800px) {
    .job-name {
      max-width: 150px;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      text-align: left;
    }
  }
  .job-button-filter {
    width: 100px;
    height: 34px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    border-radius: 8px;
    border: none;
    color: white;
    @media only screen and (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
}
@media only screen and (max-width: 1310px) {
  .card-job-public,
  .filter-sort-public {
  }
}
@media only screen and (max-width: 1080px) {
  .card-job-public,
  .filter-sort-public {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .card-job-public,
  .filter-sort-public {
    width: 80%;
  }
}

@media only screen and (max-width: 460px) {
  .card-job-public,
  .filter-sort-public {
    width: 100%;
  }
}
.no-job-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  .no-job-image-container {
    position: relative;
    width: 50%;
    @media only screen and (max-width: 650px) {
      width: 50%;
    }
  }
  .text-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 24px;
  }
  .text-top {
    font-size: 25px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    color: #9b9b9b;
    @media only screen and (max-width: 650px) {
      font-size: 20px;
    }
  }
  .text-bottom {
    font-size: 45px;
    font-weight: 700;
    color: #009a78;
    cursor: pointer;
    @media only screen and (max-width: 650px) {
      font-size: 30px;
    }
  }
}
.text-addsocial {
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.add-social-job-card {
  margin-top: 1rem;
  width: 400px;
  max-width: 400px;
  color: #60a9ac;
  // background-color: white;
  border: 0 !important;
  padding: 10px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 400px) {
    width: 180px;
  }

  cursor: pointer;
  &,
  .icon,
  p {
    transition: all 0.3s ease-in-out;
  }

  .icon {
    font-size: 60px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  &:hover {
    .icon {
      transform: scale(1.2);
    }
    p {
      transform: scale(1.05);
    }
  }

  &:active {
    .icon,
    p {
      transform: scale(1);
    }
  }
}

@keyframes changewidth {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
.alert-suggested-job {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.alert-other-job {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.alert-suggested-job {
  position: relative;
  color: white;
  background: #ff6174;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  z-index: 12;
  margin-left: 4px;
}
.alert-other-job {
  position: relative;
  color: white;
  background: #ff6174;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  z-index: 12;
  margin-left: 4px;
}
.card-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
