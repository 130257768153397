.job-img {
  position: relative;
  border-radius: 8px;
  width: fit-content;
  background-color: #77ce6f;
  margin: 3px;
  overflow: hidden;
  height: 200px;
  padding: 0;
  cursor: pointer;
  i {
    position: absolute;
    top: 4%;
    right: 0px;
    transform: translate(-50%, -23%);
    font-size: 0.8em;
    padding: 0.2rem;
    border-radius: 50%;
    background-color: #b4ff9f;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}
