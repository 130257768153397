.marketer-campaign-container {
  width: 60%;
  margin: auto;

  .no-campaign-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .no-campaign-image-container {
      position: relative;
      width: 60%;
      @media only screen and (max-width: 650px) {
        width: 80%;
      }
    }
    .text-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 24px;
    }
    .text-top {
      font-size: 45px;
      font-weight: 700;
      color: #9b9b9b;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
    .text-bottom {
      font-size: 45px;
      font-weight: 700;
      color: linear-gradient(104deg, #6b99ca, #6bc6b3);
      cursor: pointer;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
  }
  .card-containerMarket {
    height: auto;
    @media only screen and (max-width: 650px) {
      width: 100%;
    }

    .card {
      border: 'none';
      padding: 0;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      cursor: pointer;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px #a3a3a3;

      .card-body {
        padding: 0;
      }
      .card-title {
        padding: 10px 30px 10px 30px;

        @media only screen and (max-width: 450px) {
          padding: 10px 10px 0px 10px;
        }
      }
    }
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: nowrap;
  }
  .header-campaign-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .add-campaign-button {
    margin-left: 16px;
    padding: 0 15px;
    box-shadow: none;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    height: 34px;
    color: white;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
  }
  .search-add-campaign-container {
    display: flex;
    align-items: center;
  }
  .campaign-name-title {
    max-width: 300px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    @media only screen and (max-width: 800px) {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0em;
      max-width: 250px;
    }
  }

  .brand-name-title {
    max-width: 400px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    color: #a3a3a3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  .status-image {
    position: absolute;
    width: 285px;
    height: 190px;
  }
  .group-footer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    @media only screen and (max-width: 450px) {
      width: 95%;
    }
  }
  .column-group {
    // margin-bottom: 1.5rem;
    display: flex;
    // min-width: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 650px) {
      min-width: 0px;
    }
  }
  .price {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .job {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .job-count {
    max-width: 400px;
    font-size: 16px;
    font-weight: 700;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .influencer {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .follower {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .price-num {
    max-width: 400px;
    font-weight: 600;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
      min-width: 0px;
    }
  }
  .influencer-num {
    max-width: 400px;
    font-size: 14px;
    font-weight: 600;
    color: #ffff;
  }
  .follower-num {
    max-width: 400px;
    font-size: 14px;
    font-weight: 600;
    color: #ffff;
  }
  .edit-delete-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 41px;
    &:hover {
      transform: scale(1.35);
    }
  }
  .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 41px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-bottom: 6px;
  }
  @media only screen and (max-width: 1800px) {
    .header-campaign-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 800px) {
    .job-name-title {
      max-width: 400px;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      text-align: left;
    }
    .brand-name-title {
      max-width: 400px;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      color: #a3a3a3;
      text-align: left;
    }
  }
  @media only screen and (max-width: 691px) {
    .add-campaign-button {
      width: 98%;
      margin-right: 30px;
      padding: 0 15px;
      box-shadow: none;
      background: linear-gradient(104deg, #6b99ca, #6bc6b3);
      height: 34px;
      color: white;
      border-radius: 8px;
      border: none;
    }
    .search-add-campaign-container {
      flex-direction: column;
    }
    .header-campaign-container {
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      align-items: stretch;
    }

    .edit-delete-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1310px) {
  .marketer-campaign-container {
    width: 50%;
  }
}
@media only screen and (max-width: 1080px) {
  .marketer-campaign-container {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .marketer-campaign-container {
    width: 80%;
  }
}

@media only screen and (max-width: 460px) {
  .marketer-campaign-container {
    width: 100%;
  }
}

.add-social-card {
  min-height: 235px;
  margin-bottom: 0px;
  width: 180px;
  max-width: 240px;
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: linear-gradient(104deg, #6b99ca, #6bc6b3);

  @media (max-width: 400px) {
    width: 180px;
  }

  cursor: pointer;
  &,
  .icon,
  p {
    transition: all 0.3s ease-in-out;
  }

  .icon {
    font-size: 60px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  &:hover {
    .icon {
      transform: scale(1.2);
    }
    p {
      transform: scale(1.05);
    }
  }

  &:active {
    .icon,
    p {
      transform: scale(1);
    }
  }
}
.status-nav-tabs-marketer {
  cursor: pointer;
  width: 200px;
}
.card-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-button-container {
  width: 100%;
  height: 150px;
  border: 1px white solid;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../../assets/images/other/bg-horizontal.png');
  box-shadow: 0px 4px 4px 0px #a3a3a3;
  margin-bottom: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  > :hover {
    transform: scale(1.2);
  }
}

.add_gleam-job-container {
  width: 100%;
  // border: 2px #e9edee dashed;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 13px 0;
  background-color: white;
}

.gleam-job-container {
  width: 100%;
  border: 1px #e9edee solid;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 13px 0;
}

.gleam-job-container-header {
  width: 100%;
  background-color: #e9e9ef;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0;
}

.gleam-job-container-detail {
  border: 1px #e9edee solid;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 13px 0;
}

.add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-button-title {
  color: aliceblue;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 16px;
  }
}
.campaign-header {
  display: flex;
  position: absolute;
  border-radius: 5px;
  background: #6bc6b3;
  z-index: 99;
  left: 28%;
  padding: 1px 40px 1px 40px;
  font-weight: bold;
  font-size: 16px;
  color: white;
}
// .card-container-s {
//   border: white solid 2px;
// }
.cards-container {
  padding-top: 20px;
}

.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.notfound-title {
  margin: 20px 0px 0px;
  font-weight: bold;
  font-size: 20px;
}

.tab-width {
  width: 200px;
  cursor: 'pointer';
  @media (max-width: 1563px) {
    width: 100px;
  }
  @media (max-width: 1520px) {
    width: 64%;
  }
  @media (max-width: 1488px) {
    width: 67%;
  }
  @media (max-width: 1423px) {
    width: 70%;
  }

  @media (max-width: 556px) {
    width: 100%;
    justify-content: center;
  }
}
.bg-image {
  background-image: url('../../../../assets/images/other/ICON-background-total-Balance.png');
}

.tab-width-campaign {
  border: 1px solid black;
  @media (max-width: 1311px) {
    width: 150px;
  }
  @media (max-width: 1213px) {
    width: 130px;
  }
  @media (max-width: 784px) {
    width: 100px;
  }
  @media (max-width: 482px) {
    width: 95px;
  }
  @media (max-width: 463px) {
    width: 92px;
  }
  @media (max-width: 392px) {
    width: 90px;
  }
  @media (max-width: 362px) {
    width: 80px;
  }
  @media (max-width: 332px) {
    width: 75px;
  }
  @media (max-width: 317px) {
    width: 70px;
  }
  @media (max-width: 302px) {
    width: 65px;
  }
  @media (max-width: 287px) {
    width: 60px;
  }
  @media (max-width: 272px) {
    width: 55px;
  }
}
