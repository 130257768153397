.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: scroll;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.toggle-change-detail {
  position: fixed;
  bottom: 0;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  width: 100%;
  height: 50px;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  color: white;
}
.select-info {
  :hover {
    color: rgb(250, 215, 73);
  }
}
