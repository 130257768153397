.profile-menu-container {
  .navbar-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .border-pic-profile {
    border: 1px solid black;
  }
  .navbar-profile-chevron-down-container {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 9px;
    .navbar-profile-chevron-down {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #e3e6ea;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .navbar-profile-chevron-top-container {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    // background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-top: 9px;
    .navbar-profile-chevron-down {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #e3e6ea;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.marketer-sidebar .marketer-menu-sidebar,
.marketer-sidebar .fs-5 {
  color: black;
}
.marketer-sidebar .marketer-menu-sidebar i {
  color: white;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: black;
  margin-right: 10px !important;
}

i.fa.fa-chevron-right {
  color: black;
}
.lang-sidebar {
  display: flex !important;
  flex-direction: row !important;
  padding: 0.62rem 1.5rem;
  align-items: center;
}
// .lang-sidebar-content:active {
//   background: black !important;
//   color: white !important;
//   border: 1px solid black !important;
//   cursor: pointer;
//   margin-top: 10px !important;
//   padding-left: 5px !important;
//   padding-right: 5px !important;
//   border-radius: 5px !important;
//   height: 25px !important;
// }
// .section-sidebar-1 i {
//   color: white;
//   display: inline-flex !important;
//   align-items: center !important;
//   justify-content: center !important;
//   width: 32px;
//   height: 32px;
//   border-radius: 50%;
//   background-color: black;
//   margin-right: 10px !important;
// }
