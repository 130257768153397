//
// _header.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: $header-bg;
  // border-bottom: 1px solid $border-color;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  align-items: center;
  margin: 0 auto;
  height: $header-height;

  .dropdown .show {
    &.header-item {
      background-color: $gray-100;
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  width: $navbar-brand-box-width;
  background: $sidebar-bg;
  border-right: 1px solid $sidebar-border-color;
  box-shadow: 0px 2px 2px $sidebar-bg;
}

.logo {
  line-height: 69px;
  color: inherit !important;

  .logo-sm {
    display: none;
  }
}

.logo-txt {
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
  margin-left: 5px;
}

.logo-light {
  display: none;
}

body:not([data-sidebar-size='sm']) {
  #vertical-menu-btn {
    margin-left: -52px;
    margin-right: 20px;
    @media (max-width: 991.98px) {
      margin-left: 0;
    }
  }
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 40px} / 2) 0;
  position: relative;

  .form-control {
    border: none;
    height: 40px;
    padding-left: 17px;
    padding-right: 50px;
    background-color: $topbar-search-bg;
    box-shadow: none;
  }
  .btn {
    // position: absolute;
    // right: 3px;
    // top: 3px;
    height: 34px;
    padding: 0 10px;
  }
}

.layout-mode-light {
  display: none;
}

body[data-layout-mode='dark'] {
  .layout-mode-dark {
    display: none;
  }
  .layout-mode-light {
    display: inline-block;
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  position: relative;
  background-color: #1a1a1a;
  // calc(#{$grid-gutter-width} / 2)
  min-height: 100vh;
  padding: calc(#{$header-height+40px}) 2vh $footer-height 2vh;
  // padding: 2vh 2vh $footer-height 2vh;
  // background-image: linear-gradient(
  //   210deg,
  //   rgba(93, 228, 85, 0.6) 0%,
  //   #14c0aa 100%
  // ) !important;

  // @media (max-width: 770px) {
  //   // padding: calc(#{$header-height+40px}) 4vh $footer-height 4vh;
  //   padding: 6vh 2vh $footer-height 2vh;
  // }
  @media (max-width: 992px) {
    padding: calc(#{$header-height+40px}) 2vh $footer-height 2vh;
    // padding: 6vh 2vh $footer-height 2vh;
  }
}
.page-content.admin-balance h5,
.page-content.admin-balance span,
.page-content.admin-balance h4 {
  color: white !important;
}
.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;
  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 40px;
  width: 40px;
  background-color: $gray-600;
  padding: 0.5px;
}

.profile-image {
  height: 100px;
  width: 100px;
  background-color: $gray-600;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.row-content a {
  color: white !important;
}

.notification-item {
  .d-flex {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-100;
    }
    &:hover p,
    &:hover h6 {
      background-color: $gray-100;
      color: black !important;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: $gray-100;
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle='fullscreen'] {
    .bx-fullscreen::before {
      content: '\ea3f';
    }
  }
}

body[data-topbar='dark'] {
  #page-topbar {
    background-color: $header-dark-bg;
    border-color: $gray-dark-300;
  }
  .navbar-brand-box {
    background-color: $header-dark-bg;
    border-color: $header-dark-bg;
    box-shadow: 0px 0px 2px $header-dark-bg;
    .logo {
      color: $white !important;
    }
  }
  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }

    &.border-start,
    &.border-end {
      border-color: rgba($white, 0.1) !important;
    }

    &.bg-soft-light {
      background-color: rgba($white, 0.04) !important;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: rgba($topbar-search-bg, 0.07);
      color: $white;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout='horizontal'] {
  .navbar-brand-box {
    width: auto;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    @media (min-width: 992px) {
      padding-left: 0;
      margin-right: 10px;
    }
  }
  .page-content {
    margin-top: $header-height;
    padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }

  .navbar-header {
    @media (min-width: 992px) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }

  &[data-sidebar='dark'] {
    .navbar-brand-box {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

@media (max-width: 992px) {
  body[data-layout='horizontal'] {
    .page-content {
      margin-top: 15px;
    }
  }
}

body[data-layout-mode='dark'] {
  .page-content {
    background-color: unset !important;
  }

  .header-item {
    &.border-start,
    &.border-end {
      border-color: rgba($white, 0.1) !important;
    }

    &.bg-soft-light {
      background-color: rgba($white, 0.04) !important;
    }
  }

  #page-topbar {
    background-color: $header-dark-bg;
    border-color: $gray-dark-300;
  }

  .app-search {
    // Form Control
    .form-control {
      color: $gray-dark-500;
      background-color: lighten($gray-dark-200, 2%);
      border: 1px solid $gray-dark-300;
    }
  }

  .notification-item {
    .d-flex:hover {
      background-color: lighten($gray-dark-200, 4%);
    }
  }

  // Dropdown with Icons
  .dropdown-icon-item {
    color: $gray-dark-500;

    &:hover {
      background-color: $gray-dark-300;
    }
  }

  &[data-sidebar='light'] {
    @media (min-width: 992px) {
      &[data-topbar='light'] {
        .navbar-brand-box {
          .logo {
            color: $dark !important;
          }
        }

        #vertical-menu-btn {
          color: $dark;
        }
      }
    }
  }

  .navbar-header {
    button i.fa-bars {
      color: #9a9585 !important;
    }
  }
}
.form-control-token {
  background-color: white;
  height: 37px;
  border: none;
  padding: 0.47rem 0.7rem;
  margin-top: 1px;
  border-radius: 0.25rem 0 0 0.25rem;
  width: 100%;
}
.border-radius-control {
  border-radius: 50px;
}
