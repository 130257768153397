.job-detail-collapse3-container {
  width: 60%;
  margin: auto;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  // transform: translateY(-45px);
  .job-detail-collapse {
    // padding: 1rem 1.5rem;
  }
  .influencer-card {
    padding: 1rem 1.5rem;
  }
  .job-detail-btn {
    font-size: 1.2em;
    // font-weight: bold;
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 15px;
    margin-top: -10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: transparent;
  }
  .border-radius-card-close {
    border-radius: 25px;
  }
  .border-radius-card-open {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .job-detail-flex-container {
    position: relative;
    padding: 0;
    text-align: center;
    background: linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    // background: linear-gradient(299.46deg, #ff94df 15.75%, #44c0fa 74.37%);
    .work-icon-container {
      @media only screen and (max-width: 680px) {
        display: none;
      }
    }
    .icon-check {
      position: absolute;
      background-color: green;
      border-radius: 50%;
      width: 15px;
      color: white;
      position: absolute;
      height: 15px;
      border: 1px solid green;
      text-align: center;
      line-height: 23px;
      left: -1rem;
      transform: translate(30px, 0px);

    }
    .job-detail-number {
      background: white;
      width: 20px;
      height: 20px;
      padding: auto;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 50%;
      color: black;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .job-detail-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 20px;
      align-items: center;
      justify-content: space-between;
      position: relative;

      text-align: center;
      cursor: pointer;
      width: 100%;

      @media only screen and (max-width: 550px) {
        margin-left: 10px;
        // bottom: 0.5rem;
      }
    }
  }
}
