.card-container-group {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
}
.card-wrapper-s {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  min-width: 200px;
  min-height: 250px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding-bottom: 50px;
  }
  @media screen and (max-width: 447px) {
    padding-top: 10px;
    padding-bottom: 60px;
  }
}
.button-signin {
  position: absolute;
  margin-bottom: -200px;
  @media screen and (max-width: 700px) {
    margin-bottom: -200px;
  }
}
.item1 {
  width: 100%;
  max-width: 423px;
  border-radius: 8px;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
}
.item3 {
  width: 100%;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  border-radius: 8px;
}
