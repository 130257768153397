@import './icons.scss';

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";

/* close button **/
.close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto
        no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    span {
        display: none;
    }
}

body[data-layout-mode=dark] {
    .close {
        background: transparent escape-svg($btn-close-dark-bg) center / $btn-close-width auto
        no-repeat !important;
    }
}
.card-ad-primary {
    background-color: $primary !important;
    cursor: pointer;
}
/* drawer */
.react-drawer-drawer {
    z-index: 9999;
    background: #fff;
}
.drawer-main-menu {
    padding-top: 8px;
    padding-bottom: 8px;
}
.drawer-menu a i {
    min-width: 2rem;
    display: inline-block;
    min-width: 1.75rem;
    padding-bottom: 0.125em;
    font-size: 1.25rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #7f8387;
    transition: all 0.4s;
}
.drawer-menu a {
    display: block;
    padding: 0.625rem 1.5rem;
    color: #545a6d;
    position: relative;
    font-size: 13px;
    transition: all 0.4s;
}
.drawer-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
    z-index: 5000 !important;
}
.rangeslider__handle:focus {
    outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
    float: right !important;
}
.search-label {
    float: right;
}
.react-drawer-overlay {
    z-index: 9999;
}

/* rating**/
.rating-container {
    background-color: transparent !important;
}

/* form editor **/
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
    padding-left: 15px;
}
.dz-message {
    text-align: center;
    padding: 30px;
}

.search-box .search-icon-search {
    font-size: 16px !important;
    position: absolute !important;
    left: 13px !important;
    top: 0 !important;
    line-height: 38px !important;
}

//Two verification Page
.verification {
    input {
        &:focus {
            border: none;
            outline: none !important;
        }
    }
}

@media (max-width: 768px) {
    .verification {
        input {
            width: 30px !important;
        }
    }
}

@media (max-width: 320px) {
    .verification {
        input {
            width: 30px !important;
            height: 30px !important;
            font-size: 15px !important;
        }
    }
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #5156be !important;
}
.preview-thumbsnav {
    display: flex !important;
    list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
    background: #5156be !important;
}
.flatpickr-calendar.arrowBottom:after {
    border-top-color: #5156be !important;
}
.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #5156be !important;
}

// Flatepicker
.flatpickr-months,
.flatpickr-weekdays,
.flatpickr-monthDropdown-months {
    background-color: #42a5f5 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
    background-color: #42a5f5 !important;
}

