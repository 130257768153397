@keyframes changewidth {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
.disable-suggest-jobx {
  display: none;
  visibility: hidden;
}

.alert {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.alert-suggest-job {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.sub-nav-bar-container {
  box-shadow: 0px 0px 25px 0px #6099a340;
  transform: translateY(-10px);
  .alert {
    position: absolute;
    top: 15%;
    left: 8%;
    color: #ff6174;
    z-index: 12;
    width: 20px;
    height: 20px;
    width: fit-content;
    height: fit-content;
  }
  .alert-suggest-job {
    position: absolute;
    top: 21%;
    right: 41%;
    color: white;
    background: #ff6174;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    z-index: 12;
  }
  .disable-suggest-job {
    display: none;
  }

  .disable-alert {
    display: none;
  }
  .sub-nav-data {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .sub-nav-content {
      display: flex;
      flex-direction: row;
      padding: 2px 10px;

      .sub-nav-item {
        width: fit-content;
        height: fit-content;
        flex-direction: row;
        width: 200px;
        padding: 1% 2.5%;

        h3 {
          color: #495057;
          margin: 0;
          font-size: 18px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #495057;
        }
      }
      .sub-nav-icon {
        color: white;
        margin: 5px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background: linear-gradient(104deg, #6b99ca, #6bc6b3);
        border-radius: 12px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        font-size: 22px;
      }
    }
    .sub-nav-content:hover {
      cursor: pointer;
      background-color: #f3fcf9;
      border-radius: 8px;
    }
  }
  .sub-nav-data-new {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .sub-nav-content {
      display: flex;
      flex-direction: row;
      padding: 2px 10px;

      .sub-nav-item {
        width: fit-content;
        height: fit-content;
        flex-direction: row;
        width: 200px;
        padding: 1% 2.5%;

        h3 {
          color: white;
          margin: 0;
          font-size: 18px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: white;
        }
      }
      .sub-nav-icon {
        color: black;
        margin: 5px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background: white;
        border-radius: 12px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        font-size: 22px;
      }
    }
    .sub-nav-content:hover {
      cursor: pointer;
      background-color: #f3fcf9;
      border-radius: 8px;
    }
    .sub-nav-content:hover h3,
    .sub-nav-content:hover p {
      color: black;
    }
    .sub-nav-content:hover .sub-nav-icon {
      color: white;
      background: black;
    }
  }
  .arrow-up-left {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 30px solid black;
    position: absolute;
    top: -7%;
    left: 52%;
  }
  .arrow-up-right {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 30px solid white;
    position: absolute;
    top: -7%;
    left: 44%;
  }

  .arrow-up-right-advertiser {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 30px solid white;
    position: absolute;
    top: -4%;
    left: 45%;
  }

  .sub-nav-data-odd {
    width: 80% !important;
  }
}
