.advertise-type, .banner-type {
  .main-card {
    padding: 1.5rem;
  }

  img {
    height: auto;
    width: 12rem;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .advertise-type, .banner-type {
    img {
      width: 11rem !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .advertise-type {
    img {
      width: 10rem !important;
    }
  }

  .banner-type {
    img {
      width: 8rem !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .advertise-type {
    img {
      width: 8rem !important;
    }
  }

  .banner-type {
    img {
      width: 8rem !important;
    }
  }
}