.bread-crumb-20 {
  padding: 0 20rem;
  @media only screen and (max-width: 1400px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 1000px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 780px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 650px) {
    padding: 0 4rem;
  }
  @media only screen and (max-width: 570px) {
    padding: 0 2rem;
  }
}
.job-index-influencer {
  width: 40%;
  margin: auto;
  .no-campaign-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .no-campaign-image-container {
      position: relative;
      width: 60%;
      @media only screen and (max-width: 650px) {
        width: 80%;
      }
    }
    .text-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 24px;
    }
    .text-top {
      font-size: 45px;
      font-weight: 700;
      color: #9b9b9b;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
    .text-bottom {
      font-size: 45px;
      font-weight: 700;
      color: #009a78;
      cursor: pointer;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
  }
  .card-job-index {
    height: auto;

    @media only screen and (max-width: 550px) {
      width: 100%;
    }
    .card {
      border: 'none';
      padding: 0;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px #a3a3a3;
      .card-body {
        padding: 0;
      }
      .card-title {
        padding: 10px 30px 0px 30px;
        // margin-bottom: 4px;

        @media only screen and (max-width: 700px) {
          padding: 10px 10px 0px 10px;
        }
        @media only screen and (max-width: 650px) {
          padding: 10px 20px 0px 20px;
        }
        @media only screen and (max-width: 500px) {
          padding: 10px 10px 0px 10px;
        }
        @media only screen and (max-width: 400px) {
          padding: 10px 10px 0px 10px;
        }
      }
    }
    &:hover {
      box-shadow: 0px 4px 4px 0px #a3a3a3;
      filter: drop-shadow(0px 4px 4px #a3a3a3);
      border-radius: 16px;
      scale: calc(100%);
    }
  }

  .brand-name {
    max-width: 400px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 400px) {
      gap: 15px;
    }
    @media only screen and (max-width: 650px) {
      font-size: 16px;
    }
  }
  .product-name {
    max-width: 220px;
    font-size: 16px;
    font-weight: 600;
    color: #495057;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    @media only screen and (max-width: 650px) {
      font-size: 12px;
      margin-top: 6px;
    }
    @media only screen and (max-width: 500px) {
      max-width: 200px;
    }
    @media only screen and (max-width: 400px) {
      max-width: 180px;
    }
  }
  .company-name {
    max-width: 400px;
    font-size: 14px;
    font-weight: 600;
    color: #a3a3a3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
  .date {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
  .brand-name-title {
    max-width: 400px;
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    color: #ffff;
    letter-spacing: 0em;
    text-align: left;
  }
  .influencer-name {
    width: 110px;
    font-size: 14px;
    font-weight: 400;
    color: #495057;
    text-align: center;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: 650px) {
      font-size: 14px;
      width: 80px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .social-type {
    max-width: 400px;
    font-size: 14px;
    color: #495057;
    font-weight: 400;
  }
  .content-type {
    max-width: 400px;
    font-size: 14px;
    color: #495057;
    line-height: 10px;
    font-weight: 400;
  }
  .cancel-reason {
    color: rgb(163, 163, 163);
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .price {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
  .status-image {
    position: absolute;
    width: 285px;
    height: 190px;
  }

  .search-filter-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 650px) {
      .clear-button {
        width: 98%;
        margin-right: 15px;
        padding: 0 15px;
        box-shadow: none;
        background: linear-gradient(104deg, #6b99ca, #6bc6b3);
        height: 34px;
        color: white;
        border-radius: 8px;
        border: none;
      }
    }
  }
  .group-social {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: 0.5rem;
    margin-bottom: 3px;
    // margin-left: 1rem;
    @media only screen and (max-width: 450px) {
      width: 90%;
      margin-bottom: 3px;
      margin-left: 0;
    }
  }
  .column-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 1rem;
    // min-width: 150px;
    @media only screen and (max-width: 650px) {
      min-width: 0px;
    }
  }
  .row-social-media {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 650px) {
    .search-filter-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media only screen and (max-width: 800px) {
    .job-name {
      max-width: 150px;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      text-align: left;
    }
    .brand-name {
      max-width: 150px;
      // font-size: 18px;
      font-weight: 600;

      color: #495057;
      text-align: left;
    }
  }
  .job-button-filter {
    width: 100px;
    height: 34px;
    background: #009a78;
    border-radius: 8px;
    border: none;
    color: white;
    @media only screen and (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 1310px) {
  .job-index-influencer {
    width: 50%;
  }
}
@media only screen and (max-width: 1080px) {
  .job-index-influencer {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .job-index-influencer {
    width: 80%;
  }
}

@media only screen and (max-width: 460px) {
  .job-index-influencer {
    width: 100%;
  }
}

.price-all {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: '2px';
  overflow: hidden;
  margin-top: 6px;

  @media only screen and (max-width: 1556px) {
    width: 100%;
  }
}
.currency {
  font-size: 18px;
  @media only screen and (max-width: 1556px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 668px) {
    font-size: 10px;
  }
}
.prices {
  color: #495057;
  margin-right: 4px;
  font-size: 20px;
  max-height: 40px;
  @media only screen and (max-width: 1556px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 668px) {
    font-size: 12px;
  }
}
.card-detail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
}
.card-detail-social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid gray;
  margin: 0px;
  max-width: 120px;
  width: 100%;
  @media only screen and (max-width: 400px) {
    max-width: 300px;
    flex-direction: row;
    border-left: 0;
    border-top: 1px solid gray;
    padding-top: 10px;
    margin-top: 7px;
  }
}
.card-detail-social-side {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (max-width: 400px) {
    width: 100%;
    justify-content: center;
    gap: 30px;
    align-items: center;
    flex-direction: row;
  }
}
.card-detail-job-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 400px) {
    gap: 15px;
  }
}
.gapped {
  @media only screen and (max-width: 400px) {
    gap: 15px;
  }
}
.search-filter-container.job-influ-page {
  width: 62%;
  justify-content: space-between;
  @media only screen and (max-width: 650px) {
    width: 100%;
    justify-content: center;
  }
}
