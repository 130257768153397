.password-field-container {
  position: relative;

  .eye-toggle-container {
    position: absolute;
    top: -70%;
    right: .5rem;
    z-index: 1000;
    cursor: pointer;
  }
}