.influencer-width {
  margin-left: 190px;
  width: 60%;
}
.nav-scrollbar-none.nav-tabs-custom.wallet-page-nav {
  height: 50px;
  overflow-y: hidden;
}
.nav-scrollbar-none.nav-tabs-custom.mb-3.wallet-tab h5 {
  color: white;
  padding: 10px;
}
.tab-active-wallet h5 {
  background-color: white;
  padding: 10px;
  color: black !important;
}
