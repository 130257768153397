.content-price-table-container {
  .hide-icon {
    display: none;
  }
  .btn-delete-request {
    color: white;
    background: #f21717;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 2px 16px;
    border: none;
  }
  .confirm-button {
    color: white;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 2px 14.25px;
    border: none;
  }

  .input-container {
    outline: none;
    width: 100px;
    border: 1px solid rgb(207, 210, 207);
    border-radius: 8px;
    text-align: center;
    color: #6b99ca;
  }

  .price-edit-button-container {
    margin: 10px auto 0px auto;
    width: 90px;
    height: 30px;
  }
  .price-edit-button {
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }

  .price-rate-button {
    font-size: 14px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: black;
    cursor: pointer;
  }
}
