.renew-button {
  border-radius: 3rem;
  width: 160px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(104deg, #6b99ca, #6bc6b3);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;
  span {
    font-weight: bold;
    background: #6b99ca;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (max-width: 285px) {
        font-size: 11px;
      }
  }

  &:hover {
    box-shadow: none;
    color: white;
    transition: all 0.5s ease-in-out;

    span {
      color: #fff;
      background: none;
      background-color: none;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
    }
  }
  &:active {
    transition: all 0.1s ease-in-out;
    transform: scale(0.95);
  }
}
