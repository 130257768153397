.main-page-banned {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  color: black;
  background-color: white;
  border: black 1px solid;
  border-radius: 15px;
  padding: 40px 0 40px 0;
}
.main {
  font-size: 20px;
  font-weight: bold;
}
.button-logout-contact {
  display: flex;
  align-items: center;
  gap: 50px;
}
