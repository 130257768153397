.notification-icon {
  color: white;
  background: red;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}
@keyframes changewidth {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
.disable-suggest-jobx {
  display: none;
  visibility: hidden;
}

.enable-img {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  margin-left: 12px;
}

.alert-suggest-job {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.alert-suggest-job-main {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.disable-img {
  display: none;
}

.alert-suggest-job {
  color: white;
  background: #ff6174;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  z-index: 12;
}

.alert-suggest-job-main {
  color: white;
  background: #ff6174;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 100px;
  margin-right: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  z-index: 12;
}
.drewer {
  background-color: black;
}
