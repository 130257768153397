//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $font-weight-medium;
  }
}

.nav-tabs-custom {
  // border-bottom: 1px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    // padding: 0 10px;

    .nav-link {
      border: none;
      padding: 1rem;

      > * {
        font-size: $font-size-16 !important;
        font-weight: bold !important;

        @media (max-width: 450px) {
          font-size: 14px !important;
        }
      }

      &.profile-nave > * {
        font-size: $font-size-16 !important;
      }

      &::after {
        content: '';

        left: 0;
        right: 0;

        margin-left: auto;
        margin-right: auto;

        position: absolute;
        width: 70%;

        margin-top: 5px;
        outline: 1px solid #3b4341;
        background: white;

        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        background-color: $black;
        // border-radius: 3px;
      }
      &.active h6 {
        color: $white;
      }
      &.tab-active {
        background-color: white;
      }
      &.tab-active h6 {
        color: black;
      }
      &.tab-active h5 {
        color: black;
      }
      &.tab-unactive h6 {
        color: white;
      }
      &.tab-active-black {
        background-color: black;
      }
      &.tab-active-black h5 {
        color: white;
      }
    }
  }

  .nav-link .active-h6 {
    color: black;
  }

  &.card-header-tabs {
    border-bottom: none;

    .nav-link {
      padding: $card-cap-padding-y $nav-link-padding-x;
      font-weight: $font-weight-medium;
    }
  }
}

.nav-jobs h5 {
  color: white;
}

.nav-tabs-custom-publisher {
  border-bottom: 1px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    padding: 0 10px;

    .nav-link {
      border: none;
      padding: 0.5rem 1rem;

      > * {
        font-size: $font-size-16 !important;
        font-weight: bold !important;
      }

      &.profile-nave > * {
        font-size: $font-size-16 !important;
      }

      &::after {
        content: '';

        left: 0;
        right: 0;

        margin-left: auto;
        margin-right: auto;

        position: absolute;
        width: 70%;

        margin-top: 30px;
        outline: 1px solid #3b4341;
        background: white;

        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        background-color: #b7f1e9;
        border-radius: 3px;

        &:after {
          transform: scale(1);
        }
      }
      &.active-tab-admin {
        color: black !important;
        background-color: #ffffff;
        border-radius: 3px;

        &:after {
          transform: scale(1);
        }
      }
    }
  }

  &.card-header-tabs {
    border-bottom: none;

    .nav-link {
      padding: $card-cap-padding-y $nav-link-padding-x;
      font-weight: $font-weight-medium;
    }
  }
}
// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;

      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

body[data-layout-mode='dark'] {
  // .nav-tabs
  .nav-link {
    color: $gray-dark-500;
  }

  .nav-tabs {
    border-color: $gray-dark-300;
    .nav-link {
      border: none;

      &::after {
        content: '';
        background: #e5f5f2;
        height: 1px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        > * {
          color: $primary !important;
        }

        background-color: #e5f5f2;

        &:after {
          transform: scale(1);
        }
      }
    }
    // .nav-link {
    //     color: $gray-dark-600;
    //     &:focus,
    //     &:hover {
    //         border-color: $gray-dark-300 $gray-dark-300 $gray-dark-300;
    //     }
    //     &.active {
    //         background-color: $gray-dark-200;
    //         border-color: $gray-dark-300 $gray-dark-300 $gray-dark-200;
    //     }
    // }
  }

  .nav-pills {
    .nav-link {
      color: $gray-dark-600;
      &.active {
        color: $white;
      }
    }
  }

  .nav-item {
    .nav-link {
      &.active {
        color: $primary;
      }
    }
  }
}

@media (max-width: 992px) {
  .nav-tabs-custom {
    .nav-item {
      min-width: fit-content;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .nav-tabs-custom {
    .nav-item {
      min-width: fit-content;
      text-align: center;
    }
  }
}

@media (max-width: 576px) {
  .nav-tabs-custom {
    .nav-item {
      min-width: fit-content;
      text-align: center;
    }
  }
}

.nav-tab {
  font-size: 20px;
  font-weight: bold;

  &:hover {
    color: #329a83;
  }
  // color: #329a83;
}

// .nav-item {
//     width: 250px;
//     text-align: center;
// }

.nav-sidebar-title {
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.nav-sidebar {
  padding: 5px 0;
}

.nav-scrollbar-none {
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: row;
  list-style: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bg-white-card {
  background-color: white !important;
}

body[data-layout-mode='dark'] {
  .nav-scrollbar-none {
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    flex-direction: row;
    list-style: none;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.nav-drawer {
  &:hover {
    color: #329a83;
  }
}
button.btn-white-basic {
  padding: 5px 20px 5px 20px;
  border-radius: 50px;
  background-color: white;
  border: solid 2px white;
  color: black;
  font-size: 11px;
  font-weight: bold;
  &:hover {
    color: $white;
    background-color: $black;
    border: solid 2px white;
  }
}
.btn-deposit-addresses {
  background-color: black;
  border-radius: 0 50px 50px 0;
}
// .nav-scrollbar-none.nav-tabs-custom.mb-5.wallet-page-tabs h6 {
//   color: white;
// }
