.job-container {
  width: 60%;
  margin: auto;
  .edit-campaign-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .edit-campaign-button-container {
      display: flex;
      align-items: center;
      margin-left: 10px;
      background: linear-gradient(104deg, #6b99ca, #6bc6b3);
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
      border: none;
      color: white;
      height: 34px;
    }
  }
  .campaign-name {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    width: auto;
    @media only screen and (max-width: 650px) {
      font-size: 16px;
    }
  }
  .no-campaign-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .no-campaign-image-container {
      position: relative;
      width: 60%;
      @media only screen and (max-width: 650px) {
        width: 80%;
      }
    }
    .text-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 24px;
    }
    .text-container-new {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .text-top {
      font-size: 45px;
      font-weight: 700;
      color: #9b9b9b;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
    .text-bottom {
      font-size: 45px;
      font-weight: 700;
      color: #009a78;
      cursor: pointer;
      @media only screen and (max-width: 650px) {
        font-size: 30px;
      }
    }
  }
  .card-job-index {
    height: auto;

    @media only screen and (max-width: 650px) {
      width: 100%;
    }
    .card {
      margin-top: 15px;
      border: 'none';
      padding: 0;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px #a3a3a3;
      .card-body {
        padding: 0;
      }
      .card-title {
        padding: 10px 30px 0px 30px;

        @media only screen and (max-width: 450px) {
          padding: 10px 10px 0px 10px;
        }
      }
    }
    &:hover {
      // box-shadow: 0px 4px 4px 0px #a3a3a3;
      // filter: drop-shadow(0px 4px 4px #a3a3a3);
      // border-radius: 16px;
      // scale: calc(100%);
    }
  }

  .job-header {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }
  .search-add-job-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .add-job-button {
    height: 34px;
    margin-left: 16px;
    color: white;
    box-shadow: none;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    max-height: 55px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
  }

  .job-name-title {
    max-width: 280px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #495057;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .influencer-count {
    font-size: 16px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    color: #ffff;
    text-align: left;
  }
  .brand-name-title {
    max-width: 400px;
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    color: #ffff;
    letter-spacing: 0em;
    text-align: left;
  }
  .group-footer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    @media only screen and (max-width: 450px) {
      width: 95%;
    }
  }
  .column-group {
    display: flex;
    // min-width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 650px) {
      min-width: 0px;
    }
  }
  .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 41px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .price {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .influencer {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .follower {
    max-width: 400px;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .price-num {
    max-width: 400px;
    font-weight: 600;
    font-size: 16px;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .influencer-num {
    max-width: 400px;
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .follower-num {
    max-width: 400px;
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  .social-text {
    font-size: 14px;
    font-weight: 500;
    color: #495057;
  }
  .dropdown-status-job-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  @media only screen and (max-width: 650px) {
    .dropdown-status-job-container {
      margin: auto;
      margin-bottom: 0;
      align-items: center;
    }

    .add-job-button {
      width: 98%;
      margin-right: 30px;
      padding: 0 15px;
      box-shadow: none;
      display: flex;
      background: linear-gradient(104deg, #6b99ca, #6bc6b3);
      height: 34px;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 8px;
      border: none;
    }
    .job-header {
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
    .search-add-job-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 800px) {
    .job-name-title {
      max-width: 150px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0em;
      color: #495057;
      text-align: left;
    }
  }
}
@media only screen and (max-width: 1310px) {
  .job-container {
    width: 50%;
  }
}
@media only screen and (max-width: 1080px) {
  .job-container {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .job-container {
    width: 80%;
  }
}
@media only screen and (max-width: 460px) {
  .job-container {
    width: 100%;
  }
}
.add-button-container {
  width: 100%;
  height: 150px;
  border: 1px white solid;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  // background-image: url('../../../../assets/images/other/bg-horizontal.png');
  box-shadow: 0px 4px 4px 0px #a3a3a3;
  margin-bottom: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  > :hover {
    transform: scale(1.2);
  }
}
.add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-add-button span {
  color: black !important;
}
.add-button-title {
  color: aliceblue;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 16px;
  }
}
.editbutton {
  display: flex;
  align-items: center;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
  border: none;
  color: white;
  height: 34px;
}
.cards-container {
  padding-top: 20px;
}
.campaign-header {
  display: flex;
  position: absolute;
  border-radius: 5px;
  background: #6bc6b3;
  z-index: 99;
  left: 30%;
  padding: 1px 40px 1px 40px;
  font-weight: bold;
  font-size: 16px;
  color: white;
}
.status-nav-tabs-influencer {
  cursor: pointer;
  width: 150px;
  @media (max-width: 600px) {
    width: 100px;
  }
  @media (max-width: 450px) {
    width: 100px;
  }
}

.card-job-marketer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
  padding: 10px 30px 10px 30px;

  @media (max-width: 450px) {
    padding: 10px 20px 10px 20px;
  }
}
