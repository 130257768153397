//
//  Sweetalert2
//
.border-color{
  border: solid 5px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.accept-swal {
  z-index: 100000 !important;
}

.swal2-container {

  .swal2-title {
    font-size: $font-size-26;
    font-weight: $font-weight-medium;
  }
}

.swal2-modal {
  font-size: $font-size-14;

  > * {
    font-size: $font-size-18;
  }
}

.swal2-icon {
  &.swal2-question {
    border-color: $info;
    color: $info;
  }
  &.swal2-success {
    [class^='swal2-success-line'] {
      background-color: $success;
    }

    .swal2-success-ring {
      border-color: rgba($success, 0.3);
    }
  }
  &.swal2-warning {
    border-color: $warning;
    color: $warning;
  }
}

.swal2-styled {
  &:focus {
    box-shadow: none;
  }
}

.swal2-progress-steps {
  .swal2-progress-step {
    background: $primary;
    &.swal2-active-progress-step {
      background: $primary;
      & ~ .swal2-progress-step,
      & ~ .swal2-progress-step-line {
        background: rgba($primary, 0.3);
      }
    }
  }

  .swal2-progress-step-line {
    background: $primary;
  }
}

.swal2-loader {
  border-color: $primary transparent $primary transparent;
}

body[data-layout-mode='dark'] {
  .swal2-popup {
    background-color: $gray-dark-300 !important;
  }
  .swal2-content {
    color: $gray-dark-500;
  }
  .swal2-html-container {
    color: $gray-dark-400;
  }
}
