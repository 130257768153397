.page-content {
  height: 100%;
}

.content-container {
  width: 100%;
  height: 100%;

  .overview-container {
    width: 100%;
    height: auto;
  }

  .social-channel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    width: 70%;
    height: 100%;
    min-height: 550px;
    padding: 1rem;
    border-radius: 5px;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // background-color: #fbfaff;
    @media only screen and (max-width: 883px) {
      margin: auto;
    }
  }

  .header {
    text-align: center;
    font-size: 2em;
    font-weight: bolder;
  }
}

//? Media
@media only screen and (max-width: 883px) {
  .card-overview:last-child {
    // margin-top: 2rem;
  }
}
