.withdraw-history-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .status-container {
    display: flex;
    width: 100%;
    .withdraw-status {
      width: 250px;
      margin-right: 10px;
      @media only screen and (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    .currency-option {
      width: 250px;
      margin-right: 10px;
    }

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 650px) {
      align-items: center;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
