//
// Google font - IBM Plex Sans
//

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap');

//?: Form
.input-required {
  color: #ff5d5d;
  margin-left: 0.2rem;
  font-weight: bold;
  font-size: 1em;
}
.input-label {
  font-size: 1em;
  font-weight: 600;
  color: #464545;
}

.invalid-message {
  font-size: 1em;
  font-weight: 600;
  color: red;
  margin-top: 0.2rem;
}

//?: Card Shadow
.card-shadow {
  &-sm {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

//?: Animation
.text-hover-danger {
  &:hover {
    color: #ff5d5d !important;
  }
}

.text-underline-dark {
  position: relative;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #18272f;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.bg-hover-green {
  background: linear-gradient(#3aad96 0 0) no-repeat calc(200% - var(--p, 0%)) 100% /
    200% var(--p, 0.08em);
  transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));

  &:hover,
  &:hover.active,
  &:hover > i,
  &:hover > i::before,
  &:hover > span {
    --p: 100%;
    --t: 0.3s;
    color: #fff !important;
  }
}

.bg-hover-black {
  background: black;
  transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));

  &:hover,
  &:hover.active,
  &:hover > i,
  &:hover > i::before,
  &:hover p,
  &:hover > span {
    --p: 100%;
    --t: 0.3s;
    color: black !important;
    background-color: transparent !important;
  }
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;

  &:last-child {
    width: 100%;
    margin-bottom: 0;
  }
  &:first-child {
    width: 100%;
    margin-bottom: 0.25rem;
  }

  &-sm {
    width: 50%;
    height: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;

    &:last-child {
      width: 50%;
      margin-bottom: 0;
    }
    &:first-child {
      width: 50%;
      margin-bottom: 0.25rem;
    }
  }

  &-lg {
    width: 100%;
    height: 1rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;

    &:last-child {
      width: 50%;
      margin-bottom: 0;
    }
    &:first-child {
      width: 100%;
      margin-bottom: 0.25rem;
    }
  }
}

.animation-bounce {
  animation: bounce 1.3s linear infinite alternate;
  &-hover {
    &:hover {
      animation: bounce 1.3s linear infinite alternate;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

//?: Upload Creative Display
.btn-upload-bounce {
  &:hover {
    & img,
    & i {
      animation: bounce 1.3s linear infinite alternate;
    }
  }
}

//? Deposit
.disabled-input-deposit {
  cursor: no-drop;
  &::placeholder {
    color: #8d8989;
    font-weight: 600;
  }
  &:disabled {
    background-color: #f3efefa1;
  }
}

//? Add website
.disabled-input-meta-tag {
  cursor: no-drop;
  &::placeholder {
    color: #8d8989;
    font-weight: 600;
  }
  &:disabled {
    background-color: #f3efefa1;
  }
}
