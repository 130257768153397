.stepper-container {
  transform: scale(0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 450px) {
    transform: scale(0.8);
  }
  .stepper-active-span {
    display: flex;
    font-weight: 700;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: none;
    color: white;
    background: linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    justify-content: center;
    align-items: flex-end;
  }
  .stepper-span {
    display: flex;
    font-weight: 700;
    border-radius: 50%;
    height: 72px;
    width: 72px;
    border: none;
    color: white;
    background: linear-gradient(122.98deg, #d1d1d1 21.41%, #9b9b9b 79.1%);
    justify-content: center;
    align-items: flex-end;
  }
  .stepper-active-img {
    height: 114px;
    width: 114px;
    @media only screen and (max-width: 450px) {
      height: 80px;
      width: 80px;
    }
  }
  .stepper-img {
    height: 100px;
  }
  .stepper-img-influencer {
    height: 100px;
    transform: translateX(14%);
  }
  .stepper-img-influencer-active {
    height: 120px;
    transform: translateX(14%);
  }
  .stepper-dot {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: #47504e;
    @media only screen and (max-width: 450px) {
      transform: scale(0.8);
      height: 14px;
      width: 14px;
    }
  }
  .stepper-dot-active {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%);
    @media only screen and (max-width: 450px) {
      transform: scale(0.8);
    }
  }
}
.stepper-progressbar-container {
  width: 50%;
  margin: auto;
  @media only screen and (max-width: 850px) {
    width: 80%;
  }
  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}
.name-overflow {
  font-weight: bold;
  width: 160px;
  height: 1.5rem;
  margin-bottom: 6px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
