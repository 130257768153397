.show-more {
  &:hover {
    text-decoration: underline;
    color: rgb(105, 105, 232);
    cursor: pointer;
  }
}
.influencer-names {
  color: white;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 100px !important;
}
