.bread-crumb {
  padding: 0 15rem;
  @media only screen and (max-width: 1400px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 1000px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 780px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 700px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 570px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 375px) {
    padding: 0 1rem;
  }
}
.background-img {
  background-image: url('../../../../../assets/images/other/ICON-background-total-Balance.png');
}
.background-img-short {
  background-image: url('../../../../../assets/images/flupower/add-social-background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid white;
}

.overview-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .card-overview {
    min-width: 22rem;
    width: 22rem;
    height: 9rem;
    border-radius: 15px;
    margin: 0.5rem 1rem;
    text-align: center;
    position: relative;
    // cursor: pointer;
    z-index: 1;

    .available-job {
      position: absolute;
      bottom: 15px;
      left: 67px;
      color: black;
      display: flex;
      gap: 0.5rem;
      &:hover {
        color: black;
      }
    }

    .notification-icon {
      color: white;
      background: #ff6174;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
    }

    &-profit::before,
    &-works::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;
      filter: brightness(120%);
      z-index: 0;
    }

    &-profit {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      &::before {
        // background-image: url('./../../../../../assets/images/pages/influencer/card-profit-background.svg');
      }
    }

    &-works {
      display: flex;
      justify-content: space-around;
      align-items: center;

      &::before {
        // background-image: url('./../../../../../assets/images/pages/influencer/card-works-background.svg');
      }
    }

    span {
      font-size: 1rem;
      font-weight: bold;
      margin: auto 0.2rem;
      z-index: 1;

      &.header {
        font-size: 1rem;
        font-weight: bold;
        align-self: center;
      }

      &.bold {
        font-size: 2.5rem;
        // text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
        //   0px -5px 35px rgba(255, 255, 255, 0.3);
        animation: textJump 3s linear infinite alternate;
      }

      //? Animation
      @keyframes textJump {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }

    .work-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.display-justify {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
}
