.all-page-container {
  .remove-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
    button {
      width: fit-content;
      height: fit-content;
      padding: 10px 40px;
      margin: 40px 0;
      font-size: 25px;
      font-weight: bold;
      width: 360px;
      word-break: keep-all;
    }
  }
}
.no-campaign-image-container {
  position: relative;
  width: 60%;
  @media only screen and (max-width: 650px) {
    width: 80%;
  }
}
.text-top {
  font-size: 45px;
  font-weight: 700;
  color: #9b9b9b;
  @media only screen and (max-width: 650px) {
    font-size: 30px;
  }
}
.title-modal-share {
  font-weight: 700;
  font-size: 20px;
}
.share-modal {
  display: flex;
  justify-content: space-between;
  .link-share-box::-webkit-scrollbar {
    display: none;
  }
  .link-share-box {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .link-share-box {
    overflow-x: scroll;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: #eeeded;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    width: 400px;
    @media only screen and (max-width: 900px) {
      max-width: 480px;
    }
    @media only screen and (max-width: 600px) {
      max-width: 220px;
    }
  }
}
.example-influencer-work-container {
  margin: auto;
  max-width: 680px;
  @media only screen and (max-width: 900px) {
    max-width: 480px;
  }

  .card-post-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid rgb(218, 217, 217);
    width: 100%;
    box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.15);
    height: auto;
    background: white;
    margin-bottom: 20px;
    .lg-comment {
      padding: 15px 20px 0 20px;
      p {
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
        font-size: 26px;
        border: 1px solid rgb(198, 196, 196);
        display: flex;
        align-items: center;
      }
    }
    .post-image {
      height: 500px;
      width: auto;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .post-header-container {
      display: flex;
      justify-content: space-between;
      .content-type {
        display: flex;
        margin-right: 15px;
        margin-top: 10px;
        .content-icon {
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
      }
      .header-description-container {
        margin: 10px 15px;
        display: flex;
        align-items: flex-start;
        .influencer-name-lg-header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: 0 10px;
          .influencer-name-lg {
            font-weight: 700;
            font-size: 24px;
          }
        }

        .header-text {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .influencer-name {
            font-weight: 700;
            font-size: 20px;
          }
          .post-description {
            color: #9b9b9b;
            word-break: break-all;
            .show-more-text-css {
              cursor: pointer;
              color: #3aad96;
            }
          }
        }
        .influencer-profile {
          object-fit: cover;
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }
      }
    }
    .footer-container {
      display: flex;
      justify-content: space-between;
      .share-container {
        margin-right: 15px;
        margin-top: 10px;
      }
      .footer-description-container {
        margin: 10px 15px;
        display: flex;
        align-items: flex-start;
        .footer-text {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .company-name {
            font-weight: 700;
            font-size: 20px;
          }
          .company-description {
            color: #9b9b9b;
            word-break: break-all;
            .show-more-text-css {
              cursor: pointer;
              color: #3aad96;
            }
          }
        }
        .company-profile {
          object-fit: cover;
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
.font-size-medium {
  max-height: 90px;
  overflow-y: scroll;
  margin-right: 10px;
}
.faq-page h5,
.faq-page p {
  color: white !important;
}
.search-faq {
  display: flex;
  flex-direction: row;
}
button.btn.search-faq-btn {
  height: 44px;
  margin-top: -2px;
  width: 50px;
  border-radius: 0 5px 5px 0;
  color: white;
  background-color: black !important;
}
.search-faq input {
  border-radius: 5px 0 0 5px;
}
