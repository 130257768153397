.marketer-account-container {
  align-items: center;
  width: 100%;
  padding: auto;

  .card-container-marketer {
    margin-left: 4rem;
    padding: 20px 0;
    @media (max-width: 1000px) {
      margin-left: 0;
    }
    .item-from-controls {
      display: flex;
      flex-wrap: wrap;
      padding: 0 100px;

      @media (max-width: 1000px) {
        padding: 0 50px;
        align-items: center;
      }
      @media (max-width: 650px) {
        padding: 0;
        align-items: center;
      }

      .item-width-100 {
        width: 100%;
      }
      .item-width-50 {
        width: 50%;
        @media (max-width: 650px) {
          width: 100%;
        }
      }
      .item-width-50.text-white-marketer span {
        color: white !important;
      }
    }

    .image-profile {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      border: 5px solid;
      background: linear-gradient(180deg, #9cefee 0%, #c2f2ce 48.96%, #f7df81 100%);
      border-image-source: linear-gradient(
        180deg,
        #9cefee 0%,
        #c2f2ce 48.96%,
        #f7df81 100%
      );
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }

    .upload-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #fbfaff;
      position: absolute;
      top: -50px;
      left: 55%;
      transform: translateX(-55%);
      background: #fbfaff;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: #f8b400;
        border: 1px solid #f8b400;
        transform: translateX(-55%) scale(1.05);
      }

      &:active {
        color: #f8b400;
        border: 1px solid #f8b400;
        transform: translateX(-55%) scale(0.95);
      }
    }

    .upload-input {
      display: none;
    }

    .user-full-name {
      width: 100%;
      height: 1.2em;
      text-align: center;
      font-size: 2em;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2rem;
    }

    .text-header {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 600;
      text-align: start;
    }

    .text-display-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text-header {
        text-align: start;
        width: 40%;
        height: 100%;
        font-size: 1rem;
        font-weight: normal;
        color: #839aa8;
        white-space: wrap;
        margin: 0;
      }

      .text-content {
        text-align: start;
        width: auto;
        height: 100%;
        font-size: 1rem;
        font-weight: normal;
        margin: 0;

        .lgbt {
          margin-left: 1rem;
          background-image: linear-gradient(
            to left,
            violet,
            indigo,
            blue,
            green,
            yellow,
            orange,
            red
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .phone-number-input-container {
      $placeHolder: #000000;
      $borderColor: #a2b5bb;
      $borderInvalidColor: #eb1d36;
      margin-top: 10.5px;
      .phone-input {
        .phone-number-width {
          width: 100%;
        }
        .react-tel-input {
          width: 250px;
        }
      }
      label {
        font-size: 1.3em;
        transform: scale(0.8);
        font-weight: bold;
        margin: 0;
        color: $placeHolder;
      }
      input {
        @extend .transition;
        appearance: none;
        background-color: none !important;
        border: 1px solid $borderColor;
        line-height: 0;
        font-size: 17px;
        width: 100%;
        min-height: 46.8px;
        display: block;
        box-sizing: border-box;
        padding: 10px 15px;
        border-radius: 5px;
        color: $placeHolder;
        font-weight: 100;
        letter-spacing: 0.01em;
        position: relative;
      }
      &focus::after {
        border: none;
      }
      &focus::before {
        border: none;
      }
    }
    .time-zone-input-container {
      $placeHolder: #000000;
      $borderColor: #a2b5bb;
      $borderInvalidColor: #eb1d36;
      margin-top: 12px;

      label {
        font-size: 1.3em;
        transform: scale(0.8);
        font-weight: bold;
        margin-right: 100px;
        color: $placeHolder;
        display: table-row;
      }
      input {
        appearance: none;
        background-color: none !important;
        border: 1px solid $borderColor;
        line-height: 0;
        width: 100%;
        min-height: 29px;
        display: block;
        box-sizing: border-box;
        padding: 10px 15px;
        border-radius: 5px;
        color: $placeHolder;
        font-weight: 100 !important;
        letter-spacing: 0.01em;
        position: relative;
      }
    }
    .float-input-container {
      position: relative;
      margin-top: 2.5rem;
      // height: 70px;

      $placeHolder: #000000;
      $borderColor: #a2b5bb;
      $borderInvalidColor: #eb1d36;
      .transition {
        transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      .date-label {
        top: 40px;
        left: -5px;
        font-size: 1rem;
        font-weight: bold;
        transform: scale(0.8);
      }

      label {
        @extend .transition;
        display: inline;
        transform-origin: left center;
        color: $placeHolder;
        font-weight: 100;
        letter-spacing: 0.01em;
        font-size: 1rem;
        box-sizing: border-box;
        padding: 10px 15px;
        display: block;
        position: absolute;
        top: 40px;
        left: 10px;
        z-index: 2;
        pointer-events: none;
      }

      select {
        /* styling */
        background-color: white;
        border: 1px solid $borderColor;
        border-radius: 4px;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;

        /* reset */
        margin: 0 !important;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;

        /* arrows */
        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%),
          linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;

        &:focus {
          background-image: linear-gradient(45deg, $borderColor 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, $borderColor 50%),
            linear-gradient(to right, #ccc, #ccc);
          background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
            calc(100% - 2.5em) 0.5em;
          background-size: 5px 5px, 5px 5px, 1px 1.5em;
          background-repeat: no-repeat;
          border-color: $borderColor;
          outline: 0;
        }

        &-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }

        option {
          width: 100%;
          margin: auto;
        }
      }

      input {
        @extend .transition;
        appearance: none;
        background-color: none !important;
        border: 1px solid $borderColor;
        line-height: 0;
        font-size: 17px;
        width: 100%;
        min-height: 46px;
        display: block;
        box-sizing: border-box;
        padding: 10px 15px;
        border-radius: 5px;
        color: $placeHolder;
        font-weight: 100;
        letter-spacing: 0.01em;
        position: relative;
      }

      input,
      select {
        &:focus {
          outline: none;
          color: $placeHolder;
        }

        &:focus ~ label,
        &:not([value='']) ~ label,
        &:not([value='']):focus ~ label {
          top: -45%;
          left: -5px;
          font-size: 1.3em;
          font-weight: bold;
          transform: scale(0.8) translateY(-45%);
        }

        &:not([value='']).is-invalid ~ label,
        &:not([value='']):focus.is-invalid ~ label {
          top: -35%;
          left: -5px;
          font-size: 1.3em;
          font-weight: bold;
          transform: scale(0.8) translateY(-35%);
        }

        &[value='']:not(:focus) ~ label,
        &[value='']:focus ~ label {
          margin: 0;
        }

        &[value='']:not(:focus) ~ label {
          font-size: 1rem;
          font-weight: normal;
          left: -5px;
          top: 10%;
          transform: translateY(-10%);
        }

        &[value='']:focus ~ label {
          font-size: 1.3em;
          font-weight: bold;
          left: 0px;
          top: -35%;
          transform: translateY(-35%);
        }

        &.is-invalid {
          border: 1px solid $borderInvalidColor !important;
        }

        &.is-invalid ~ label {
          color: red;
        }
      }

      .invalid-message {
        text-align: start;
        font-size: 0.875rem !important;
      }
    }

    .btn-container-marketer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      gap: 2rem;

      & .btn-edit,
      & .btn-submit-marketer,
      & .btn-cancel {
        border-radius: 1.5rem;
        padding: 0.5rem 1rem;
        min-width: 8rem;
        font-size: 1rem;
        margin: 0 1rem !important;
        font-weight: bold;
        outline: none;
        border: none;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin: 0;
        transition: all 0.1s ease-in-out;
        text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
          0px -5px 35px rgba(255, 255, 255, 0.3);
        color: #ffffff;

        &:hover {
          transform: scale(1.05) !important;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }

        &:active {
          transform: scale(0.95) !important;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
        }
      }

      & .btn-edit,
      & .btn-submit-marketer {
        background: #3baf96;
      }

      & .btn-cancel {
        background: #73777b;
      }
    }
  }
  .css-yk16xz-control {
    border-radius: 5px !important;
  }
}
.marketer-account-container.marketer-color label {
  color: white !important;
}
label.input-fix-ab {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  margin-left: 10px;
  font-weight: bold !important;
}
