.deposit-table-history-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
