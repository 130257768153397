.custom-btn {
  .btn-custom-cancel-s {
    width: 130px;
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, #78d4ff, #fa465e);

    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-cancel-ssp {
    width: 130px;
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    border: solid 2px transparent;
    background-color: white;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:hover {
      color: white;
      background-color: transparent;
      border: solid 2px #bb3636;
    }
  }
  .btn-custom-cancel-xs {
    width: 130px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, #78d4ff, #fa465e);

    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-cancel-xsp {
    width: 130px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 2px transparent;
    background-color: white;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:hover {
      color: white;
      background-color: transparent;
      border: solid 2px #bb3636;
    }
  }
  .btn-custom-cancel-sss {
    width: 100px;
    height: 34px;
    font-size: 16px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, hsl(323, 67%, 67%), #fa465e);

    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-cancel-ssss {
    width: 100px;
    height: 34px;
    font-size: 16px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, hsl(347, 89%, 45%), #fa465e);

    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bolder;
      background: #bb3636;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-xxs {
    width: 120px;
    height: 30px;
    font-size: 11px;
    font-weight: bold;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      text-align: center;
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-xs {
    width: 130px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-xsp {
    width: 130px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 2px black;
    color: black !important;
    background-color: white;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      color: black;
    }
    &:hover {
      color: white;
      background-color: transparent;
      border: solid 2px white;
    }
  }
  .btn-custom-submit-s {
    width: 140px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-ssp {
    width: 140px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px !important;
    background-color: white;
    border: solid 2px white;
    color: black;
    &:hover {
      color: white;
      background-color: black;
      border: solid 2px white;
    }
  }
  .btn-custom-submit-sss {
    width: 180px;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-ssss {
    width: 100px;
    height: 34px;
    font-size: 18px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-mds {
    padding-left: 1.5rem;
    width: fit-content;
    padding-right: 1.5rem;
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-md {
    padding-left: 1.5rem;
    width: fit-content;
    padding-right: 1.5rem;
    height: 44px;
    font-size: 22px;
    font-weight: bold;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btn-custom-submit-mdp {
    padding-left: 1.5rem;
    width: fit-content;
    padding-right: 1.5rem;
    height: 44px;
    font-size: 22px;
    font-weight: bold;
    border-radius: 50px !important;
    background-color: white;
    border: solid 2px white;
    color: black;
    &:hover {
      color: white;
      background-color: black;
      border: solid 2px white;
    }
  }

  // .btn-custom-submit-lg {
  //   width: 300px;
  //   height: 44px;
  //   font-size: 24px;
  //   font-weight: bold;
  //   border: solid 3px transparent;
  //   background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
  //     linear-gradient(104deg, #6b99ca, #6bc6b3);
  //   background-origin: border-box;
  //   background-clip: content-box, border-box;
  //   box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
  //     rgba(0, 0, 0, 0.24) 0px 1px 2px;
  //   span {
  //     font-weight: bold;
  //     background: #6b99ca;
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //   }
  // }

  .btn-custom-all-xxs {
    width: 100px;
    height: 30px;
    font-size: 11px;
    font-weight: bold;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(104deg, #6b99ca, #6bc6b3);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    span {
      text-align: center;
      font-weight: bold;
      background: #6b99ca;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .btn-custom-submit-xxsp {
    width: 100px;
    height: 30px;
    font-size: 11px;
    font-weight: bold;
    padding: 5px 20px 5px 20px;
    border-radius: 50px !important;
    background-color: white;
    border: solid 2px white;
    color: black;
    &:hover {
      color: white;
      background-color: black;
      border: solid 2px white;
    }
  }

  .btn-custom-all-sp,
  .btn-custom-submit-sp {
    padding: 5px 20px 5px 20px;
    border-radius: 50px !important;
    background-color: white;
    border: solid 2px white;
    color: black;
    font-size: 11px;
    font-weight: bold;
    &:hover {
      color: white;
      background-color: black;
      border: solid 2px white;
    }
  }

  .btn-custom {
    border-radius: 3rem;

    // border: solid 3px transparent;
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    //   linear-gradient(101deg, #78e4ff, #ff48fa);
    // background-origin: border-box;
    // background-clip: content-box, border-box;
    // box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
    //   rgba(0, 0, 0, 0.24) 0px 1px 2px;

    &:hover {
      box-shadow: none;
      color: white;
      transition: all 0.5s ease-in-out;

      span {
        color: #fff;
        background: none;
        background-color: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
      }
    }

    &:active {
      transition: all 0.1s ease-in-out;
      transform: scale(0.95);
    }
  }

  .btn-custom-5 {
    border-radius: 5px;

    // border: solid 3px transparent;
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    //   linear-gradient(101deg, #78e4ff, #ff48fa);
    // background-origin: border-box;
    // background-clip: content-box, border-box;
    // box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
    //   rgba(0, 0, 0, 0.24) 0px 1px 2px;

    &:hover {
      box-shadow: none;
      color: white;
      transition: all 0.5s ease-in-out;

      span {
        color: #fff;
        background: none;
        background-color: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
      }
    }

    &:active {
      transition: all 0.1s ease-in-out;
      transform: scale(0.95);
    }
  }

  .btn-custom-submit-lg {
    width: 300px;
    height: 44px;
    font-size: 18px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50px !important;
    background-color: black;
    border: solid 2px white;
    color: white;
    &:hover {
      color: black !important;
      background-color: black;
      border: solid 2px black;
    }
  }
}
