.check-detail:hover {
  background-color: #dae7e97f;
  border: 1px solid rgb(26, 195, 110);
  color: rgb(26, 195, 110);
}
.check-detail {
  padding: 3px;
  border-radius: 5px;
  border: 1px solid rgb(13, 137, 75);
  color: rgb(13, 137, 75);
}
.link-job {
  border: 1px solid #1f529e;
  padding: 3px;
  border-radius: 5px;
  color: #1f529e;
}
.link-job:hover {
  background-color: #dae7e97f;
  border: 1px solid #83acf4;
  color: #83acf4;
}

.text-header {
  font-size: 14px;
  font-weight: bold;
}
.disabled {
  pointer-events: none;
  opacity: 0.5; /* Optionally, you can reduce the opacity to visually indicate the disabled state */
}
.page-content.job-tracking a {
  color: white;
}
.page-content.job-tracking a:hover {
  color: rgb(160, 160, 160);
}
input.disabled-input-deposit.form-control {
  background: white;
}
