.page-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.form-title {
  font-size: 20px;
  font-weight: bold;
}
.tab {
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  cursor: pointer;
  width: 100%;
  height: 50px;

  border-radius: 9px;
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > i {
    margin-right: 10px;
  }
}
.tab-select {
  margin-right: 5px;
}
.tab-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.check-box-social {
  font-size: 12px;
  margin: 0px 10px 0px 0px;
}
.tab-social-icon {
}
.tab-img {
  margin-left: 5px;
}
.button-next {
  margin-top: 20px;
  padding: 10px 20px;
  border: 0px;
  border-radius: 8px;
  color: white;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  > :hover {
    background: #6bc6b3;
    color: black;
  }
}
.share-containers {
  cursor: pointer;
  display: flex;
  width: 100%;
  background: #6bc6b3;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  margin-bottom: 5px;
}
.collapse-container {
  display: flex;
  align-items: center;
  height: fit-content;
  margin-top: 20px;
  width: 93% !important;
  margin: auto;
  flex-direction: column;
  border-radius: 8px;
  padding-bottom: 15px;
  background-color: white;
}
.budget-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-price {
  margin-top: 15px;
  display: flex;
  width: 50%;
  justify-content: space-around;
  gap: 30px;
  @media screen and (max-width: 500px) {
    font-size: 10px;
    gap: 10px;
    width: 25%;
  }
}
.collapse-brief {
  position: relative;
  left: 0;
}
.collapse-hashtag {
  cursor: pointer;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
  color: white;
  border: 1px solid white;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
}
.hashtag-hover {
  width: 75%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > :hover {
  }
}
.collapse-detail-copy {
  cursor: pointer;
  width: 100%;
  border: var(--bs-gray-500) 1px solid;
  border-radius: 8px;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > :hover {
    border: white;
  }
}
.collapse-footer {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.all-collapse-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px 8px 0px;
}
.page-collapse-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.allv {
  width: 100%;
  @media screen and (max-width: 1080px) {
  }
  > :hover {
    color: white;
    border: white 1px solid;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  }
}
.bb {
  border: black 1px solid;
}
.price {
  display: none;
}
.exit-button {
  cursor: pointer;
  margin-bottom: 1.2rem;
}
.quill {
  min-height: 1em;
}
