.advertiser-type-card {
  // height: 20rem;
  padding: 3rem 1rem !important;
  box-sizing: content-box;

  .img-container {
    height: 60%;
  }

  .desc-container {
    height: 40%;
  }
}

// @media (min-width: 992px) and (max-width: 1200px) {
//   .advertiser-type-card {
//     height: 18rem;
//   }
// }


// @media (max-width: 768px) {
//   .advertiser-type-card {
//     height: 16rem;
//   }
// }