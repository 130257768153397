.page-content {
  height: 100%;
}

.chanel-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .card-container {
    position: relative;
    height: max-content;
    min-height: 300px;
    width: 100%;
    max-width: 500rem;
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;

    .profile {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      height: 130px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      animation-delay: 2.5s;
      animation: profile 0.5s linear alternate;
    }

    .channel-logo {
      position: absolute;
      object-fit: cover;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      top: -53px;
      left: 55%;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      animation-delay: 2s;
      animation: channelLogo 0.5s linear alternate;
    }

    .static-container {
      text-align: center;
      padding-top: 4.5rem;
      width: 100%;

      .name {
        font-size: 2.5em;
        font-weight: bold;
        width: 100%;
        height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .static-content {
        display: flex;
        flex-wrap: wrap;
        row-gap: 4rem;
        margin-top: 2rem;

        .follower,
        .post-engagement,
        .job-score,
        .rating,
        .like {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;

          p {
            font-size: 1.2em;
            font-weight: bold;
            margin: 0;
          }
        }

        .follower {
          .icon {
            font-size: 2em;
            color: #3baf96;
          }

          .follower-count {
            font-size: 2.5em;
            font-weight: normal;
          }

          .btn-edit {
            margin-top: 0.5rem;
            width: 80px;
            height: 30px;
            background: transparent;
            border-radius: 3rem;
            border: 1px solid #333;
          }
        }

        .rating {
          .star {
            color: #ffd124;
            font-size: 1.8em;
          }
          .rating-text {
            font-size: 2.5em;
            font-weight: normal;
          }
        }

        .like {
          .icon {
            color: #ff6b6b;
            font-size: 1.8em;
            animation: heart 1s linear alternate;
          }

          .like-count {
            font-size: 2.5em;
            font-weight: normal;
          }
        }
      }
    }
  }
  .price-container {
    border-top: 1px solid rgb(221, 218, 218);
    // background: red;
    width: 100%;
    margin-top: 1rem;
    .price-post {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      margin: 1.5rem auto;
      width: 70%;
      .title-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
      }
    }
  }
  input[type='checkbox'] {
    height: 0;
    width: 0;
    // visibility: hidden;
  }

  //? Media
  @media only screen and (max-width: 530px) {
    .follower,
    .post-engagement,
    .job-score,
    .rating,
    .like {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  //? Animation
  @keyframes heart {
    0% {
      transform: scale(0.5);
    }
    25% {
      transform: scale(1.1);
    }
    40% {
      transform: scale(0.5);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes profile {
    0% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(0.5);
      width: 130px;
      height: 130px;
    }
    100% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1);
      width: 130px;
      height: 130px;
    }
  }

  @keyframes channelLogo {
    0% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      height: 130px;
    }
    25% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1.2);
      width: 130px;
      height: 130px;
    }
    45% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1);
      width: 130px;
      height: 130px;
    }
    100% {
      top: -53px;
      left: 55%;
      width: 40px;
      height: 40px;
    }
  }
}
.view-profile {
  border: 1px solid #1f529e;
  padding: 3px;
  border-radius: 5px;
  color: #1f529e;
}
.view-profile:hover {
  background-color: #dae7e97f;
  border: 1px solid #83acf4;
  color: #83acf4;
}
.view-detail {
  border: 1px solid rgb(13, 137, 75);
  padding: 3px;
  border-radius: 5px;
  color: rgb(13, 137, 75);
}
.view-detail:hover {
  background-color: #6afaa67f;
  border: 1px solid rgb(13, 137, 75);
  color: rgb(13, 137, 75);
}
.image-container {
  display: flex;
  flex-direction: column;
  margin-top: 85px;
  align-items: flex-start !important;
  text-align: start;
  padding: 0 140px 0 140px;
}
.image-container-new {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  text-align: start;
  margin-left: -110px;
  margin-top: -80px;
}
.profile-social-new,
.profile-social-new-section {
  display: flex;
  flex-direction: row;
}
.image-container span {
  width: 340px;
  font-size: 1.2rem !important;
  font-weight: bold;
}
.image-container-new span {
  width: 220px;
  font-size: 1.2rem !important;
  font-weight: bold;
}
.profile-social-input {
  margin-left: 0;
}
.profile-social-new-section {
  margin-bottom: 25px;
  margin-left: 62px;
}
.profile-social-new-section #username,
.profile-social-new-section #follower,
.profile-social-new-section #profile_path {
  border: 1px solid #a2b5bb !important;
  line-height: 0 !important;
  font-size: 17px !important;
  width: 220px !important;
  min-height: 46px !important;
  padding: 10px 15px !important;
  border-radius: 5px !important;
  color: #000000 !important;
  font-weight: 100 !important;
  letter-spacing: 0.01em !important;
  &.is-invalid {
    border: 1px solid red !important;
  }
}
.profile-social-new-section p {
  height: 2em !important;
}
.upload-images-profile {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 220px;
  min-height: 46px !important;
  &.is-invalid {
    border: 1px solid red !important;
  }
}
.upload-images-profile:focus {
  outline: none !important;
  border: 2px solid black;
}
.upload-images-profile-select {
  background-color: white;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 46px !important;
  display: block;
  width: 220px !important;
  font-size: 1em;
  padding: 0.8rem 0.5rem;
  font-family: inherit;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-left: 35px;
  &.is-invalid {
    border: 1px solid red !important;
  }
}
.invalid-message-new {
  font-size: 10px;
  font-weight: 600;
  color: red;
}
.title-social-profile {
  font-size: 1.2rem;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 1rem;
}
.profile-social-new-section-last {
  margin-bottom: 1rem;
}
#introduce {
  width: 80%;
  height: 120px;
  padding: 20px;
}
.profile-social-new-section input::file-selector-button {
  font-weight: bold;
  color: white;
  padding: 0.5em;
  border: 2px solid black;
  border-radius: 3px;
  background-color: black;
  width: 150px;
}
@media (max-width: 570px) {
  .sc-hHTYSt.eiIqvg.name {
    height: 4em;
  }
  .profile-social-new-section {
    margin-bottom: 20px !important;
    align-items: center;
  }
  .title-social-profile {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0 !important;
  }
  .image-container-new {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-top: 0 !important;
    text-align: center !important;
  }
  .profile-social-new,
  .profile-social-new-section {
    display: flex;
    flex-direction: column;
  }
  .profile-social-new-section {
    margin-left: 0;
  }
  .css-1mva6gg {
    margin-top: 0 !important;
  }
  .upload-images-profile-input {
    text-align: center;
    padding-left: 20px;
  }
}
.profile-social-new-section input[type='file'] {
  color: transparent;
}
