.select-influencer-page {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 35rem;
  padding: 0.9375rem;

  margin: 0 auto;
  color: #333;
  gap: 0.5rem;

  .test-border {
    border-bottom: 4px solid rgb(0, 0, 0);
  }

  .add-social-card {
    min-height: 13.75rem;
    width: calc(50% - 0.3125rem);
    background-color: #fff;
    padding: 0.625rem;
    line-height: normal;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &,
    .icon,
    p {
      transition: all 0.3s ease-in-out;
    }

    .icon {
      font-size: 4em;
    }

    p {
      font-size: 1.3em;
      font-weight: bold;
      margin-top: 0.5rem;
    }

    &:hover {
      .icon {
        transform: scale(1.1);
      }
      p {
        transform: scale(1.05);
      }
    }

    &:active {
      .icon,
      p {
        transform: scale(1);
      }
    }
  }
}

.select-influencer-container {
  display: grid;
  align-items: auto;
  justify-content: center;
  min-width: 2000px;
  grid-template-columns: repeat(5, 180px);
  grid-gap: 10px;
  min-width: 180px;
  justify-items: center;

  @media (max-width: 990px) {
    grid-template-columns: repeat(3, 180px);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 180px);
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(2, 180px);
  }
  @media (max-width: 375px) {
    grid-template-columns: repeat(2, 180px);
  }
}

.icon-select-influencer {
  width: 100%;
  max-width: 180px;
  height: auto;
  @media (max-width: 580px) {
    max-width: 120px;
  }

  .icon-container {
    position: relative;
    transform: translate(90%, 90%);
    width: 18px;
    height: 18px;
    margin-bottom: 0px;
    background-color: #fbfaff;
    border-radius: 50%;
    border: 1px solid #dfdfde;
    display: flex;
    justify-content: center;
    align-items: center;
    &.icon-check {
      background-color: #6bcb77 !important;

      .icon {
        font-size: 0.8em;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .icon {
      font-size: 0.8em;
      font-weight: 600;
      color: #dfdfde;
    }
  }
  .btn-request-price {
    font-size: 12px;
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #999999;
    border-radius: 15px;
    width: 60px;
    height: 30px;
    cursor: pointer;
    background: transparent;
    transition: all 0.2s ease-in-out;

    @media (max-width: 450px) {
      width: 55px;
      text-overflow: initial;
      white-space: normal;
    }

    :hover {
      border: 1px solid #3baf96;
      background: green;
      color: #ffffff;
    }
  }
}
.width-cover {
  overflow: scroll;

  width: 1200px;
  height: 800px;
  @media (max-width: 500px) {
    width: 900px;
    height: 450px;
  }
}
