.floating-input-container {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  // height: 70px;
  margin-top: 30px;
  width: 100%;
  $placeHolder: #000000;
  $borderColor: #faf8ff;
  $borderInvalidColor: #eb1d36;
  .transition {
    transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .date-label {
    top: 40px;
    left: -5px;
    font-size: 1.3em;
    font-weight: bold;
    transform: scale(0.8);
  }

  label {
    @extend .transition;
    display: inline;
    transform-origin: left center;
    color: $placeHolder;
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 10px 15px;
    display: block;
    position: absolute;
    top: 40px;
    left: 10px;
    pointer-events: none;
  }

  select {
    /* styling */
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: 4px;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */
    margin: 0 !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;

    /* arrows */
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:focus {
      background-image: linear-gradient(45deg, $borderColor 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $borderColor 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
      border-color: $borderColor;
      outline: 0;
    }

    &-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    option {
      width: 100%;
      margin: auto;
    }
  }

  input {
    @extend .transition;
    appearance: none;
    background-color: none !important;
    border: 1px solid $borderColor;
    line-height: 0;
    font-size: 17px;
    width: 100%;
    min-height: 46px;
    display: block;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 5px;
    color: $placeHolder;
    font-weight: 100;
    letter-spacing: 0.01em;
    position: relative;
  }

  input,
  select {
    &:focus {
      outline: none;
      color: $placeHolder;
    }

    &:focus ~ label,
    &:not([value='']) ~ label,
    &:not([value='']):focus ~ label {
      top: var(--topUnFocus);
      left: -5px;
      font-size: 1.3em;
      font-weight: bold;
      transform: scale(0.8) translateY(var(--topUnFocus));
      width: 100%;
      height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:not([value='']).is-invalid ~ label,
    &:not([value='']):focus.is-invalid ~ label {
      top: var(--topFocusInvalid);
      left: -5px;
      font-size: 1.3em;
      font-weight: bold;
      transform: scale(0.8) translateY(var(--topFocusInvalid));
      width: 130%;
      height: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:not([value='']):focus ~ label,
    &:not([value='']).is-invalid ~ label,
    &:not([value='']):not(:focus) ~ label {
      width: 130%;
      font-size: 17.5px;
      transform: scale(1) translateY(var(--topFocusInvalid));
      color: #495057;
    }

    &[value='']:not(:focus) ~ label,
    &[value='']:focus ~ label {
      margin: 0;
    }

    &[value='']:not(:focus) ~ label {
      font-size: 1rem;
      font-weight: normal;
      left: -5px;
      top: 10%;
      transform: translateY(-10%);
    }

    &[value='']:focus ~ label {
      font-size: 1.3em;
      font-weight: bold;
      left: 0px;
      top: var(--topFocusNoValue);
      transform: translateY(var(--topFocusNoValue));
    }

    &.is-invalid {
      border: 1px solid $borderInvalidColor !important;
    }

    &.is-invalid ~ label {
      color: red;
    }
  }

  // Text area
  .fa-question-circle:before {
    cursor: pointer !important;
  }
  .text-area-label {
    @extend .transition;
    display: inline;
    position: sticky;
    transform-origin: left center;
    color: $placeHolder;
    color: #495057;
    font-weight: 100;
    letter-spacing: 0.01em;
    box-sizing: border-box;
    padding: 0px 15px;
    display: block;
    margin-bottom: 0;
    top: 40px;
    left: 10px;
    font-size: 1rem;
    font-weight: bold;
    pointer-events: none;
  }

  .scheme-des textarea {
    @extend .transition;
    background-color: none !important;
    border: 1px solid $borderColor;
    outline: none;
    font-size: 17px;
    display: block;
    box-sizing: border-box;
    padding: 0px 15px 0px 15px;
    border-radius: 5px;
    color: $placeHolder;
    font-weight: 100;
    letter-spacing: 0.01em;
    position: relative;
    width: 100%;
    min-height: 5rem;
    max-height: 30rem;
    height: unset;
    resize: none;
  }

  .form-float {
    position: relative;
    .input-error {
      border-color: red;
    }
    .info-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.5rem;
      color: '#000000';
      background: transparent;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  textarea:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label {
    top: -5px;
    left: -5px;
    font-size: 1rem;
    opacity: 1;
  }

  textarea:not(:placeholder-shown) ~ .floating-label {
    top: -5px;
    left: -5px;
    font-size: 1em;
    opacity: 1;
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: -5px;
    top: -5px;
    right: 20px;
    transition: 0.2s ease all;
    font-size: 1.2rem;
    font-weight: bold;
  }

  // Invalid message
  .invalid-message {
    text-align: start;
    font-size: 0.875rem !important;
  }
}
