.page-content {
  width: 100%;
  height: 100%;
}

.container-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.card-container {
  position: relative;
  height: max-content;
  min-height: 300px;
  width: 100%;
  max-width: 45rem;
  margin-top: 3rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fbfaff;
  text-align: center;

  .header {
    font-size: 1.2rem;
    font-weight: bold;
    white-space: wrap;
  }

  .sub-header {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0;
  }

  .social-select-container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    .google-container {
      display: none;
    }
    .social-select-btn {
      display: flex;
      width: auto;
      width: 100%;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
    }

    .social-select-icon-group {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 2rem 3rem;

      @media (max-width: 600px) {
        margin: 0.8rem;
      }

      @media (max-width: 500px) {
        margin: 1.5rem;
      }
      @media (max-width: 400px) {
        margin: 0.67rem;
      }
      &:hover {
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.95);
      }

      @keyframes icon {
        0% {
          transform: scale(1.05);
        }
        50% {
          transform: scale(0.95);
        }
        100% {
          transform: scale(1);
        }
      }

      .img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }

      .icon-container {
        position: absolute;
        top: 0;
        right: -12px;
        width: 18px;
        height: 18px;
        background-color: #fbfaff;
        border-radius: 50%;
        border: 1px solid #dfdfde;
        display: flex;
        justify-content: center;
        align-items: center;
        &.icon-check {
          background-color: #6bcb77 !important;

          .icon {
            font-size: 0.8em;
            font-weight: 600;
            color: #ffffff;
          }
        }

        .icon {
          font-size: 0.8em;
          font-weight: 600;
          color: #dfdfde;
        }
      }

      span {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }
  .social-select-bottom-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .social-select-group {
      // position: relative;
      display: flex;
      // justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 40px;
      width: fit-content;
      // padding: 2px 10px;
      border-radius: 8px;
      margin: 0.5rem;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      padding: 0 10px 0 20px;

      .social-select-img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
      // @media (max-width: 750px) {
      //   position: relative;
      //   display: flex;
      //   height: 40px;
      //   align-items: center;
      //   cursor: pointer;
      //   width: auto;
      //   border-radius: 8px;
      //   margin: 1rem 0;
      //   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      // }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        color: black !important;
      }

      @keyframes icon {
        0% {
          transform: scale(1.05);
        }
        50% {
          transform: scale(0.95);
        }
        100% {
          transform: scale(1);
        }
      }
      .google-text {
        display: none;
      }

      .social-select-text-container {
        display: flex;
        width: 100%;
        justify-content: center;
        .social-select-text {
          font-size: 14px;
          width: auto;
          font-family: 'Roboto-Medium';
          color: #000000;
          opacity: 0.54;
          font-weight: normal;
        }
      }

      .google-icon {
        border-radius: 50%;
        width: 18px;
        height: 18px;
      }

      .disable-google-img {
        display: none;
      }
      .img {
        // border: none;
        // background-color: #ffffff;
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }

      .icon-container {
        position: absolute;
        top: 0;
        right: -12px;
        width: 18px;
        height: 18px;
        background-color: #fbfaff;
        border-radius: 50%;
        border: 1px solid #dfdfde;
        display: flex;
        justify-content: center;
        align-items: center;

        &.icon-check {
          background-color: #6bcb77 !important;

          .icon {
            font-size: 0.8em;
            font-weight: 600;
            color: #ffffff;
          }
        }

        .icon {
          font-size: 0.8em;
          font-weight: 600;
          color: #dfdfde;
        }
      }

      span {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }

  .google-btn-channel-add {
    // img {
    //   width: 18px;
    //   height: 18px;
    // }
    span {
      // height: dp(40) !important;
      // font-size: 14px !important;
      font-family: 'Roboto-Medium' !important;
      // margin-left: dp(24) !important;
      // width: fit-content !important;
      // opacity: 0.54 !important;
      // color: #000000 !important;
      // border-radius: 8dp;
    }
  }

  .btn-submit {
    margin-top: 3rem;
    border-radius: 3rem;
    width: auto;
    height: auto;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(101deg, #78e4ff, #ff48fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;

    span {
      font-weight: bold;
      background: #00ff00;
      background: #1c8326;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:hover {
      box-shadow: none;
      color: white;
      transition: all 0.5s ease-in-out;

      span {
        color: #fff;
        background: none;
        background-color: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
      }
    }

    &:active {
      transition: all 0.1s ease-in-out;
      transform: scale(0.95);
    }
  }
}
