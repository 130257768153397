.text-editor-input-container {
  .ql-editor {
    padding-top: 4px;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    min-height: 200px;
    background-color: white;
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 0;
    }
    .ql-snow .ql-size {
      width: 75px;
    }
  }
  .quill {
    padding: 0;
    border-color: red;
  }

  .editor-error {
    .ql-container {
      border-color: red;
    }
    .ql-toolbar {
      border-color: red;
    }
  }
}
