.icon-radio-input-container {
  width: 100%;

  .radio-input {
    position: relative;
    width: 100%;
    height: 8rem;
    cursor: pointer;
    display: inline-block;
    transition: all 0.1s ease-in;
    margin: auto;
    text-align: center;

    &:hover {
      transform: scale(1.1);
      // border-radius: 1rem;
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }

    &:active {
      transform: scale(0.95);
    }

    img {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -30%);
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 90%;

      &.image-full {
        width: 100%;
        height: 100%;
      }

      &.image-icon {
        width: 80%;
        height: 80%;
        align-items: center;
        // background-repeat: no-repeat;
        // background-size: cover;
        border-radius: 10px;
        // padding: 0.5rem;
        // object-fit: cover;
      }
      &.images-social {
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        // padding: 0.5rem;
        object-fit: cover;
      }
    }

    .radio-icon {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
      font-size: 2em;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      padding: 0.5rem;
    }

    label {
      position: absolute;
      color: #495057;
      bottom: -10%;
      left: 50%;
      transform: translate(-50%, -10%);
      line-height: 1rem;
      cursor: pointer;
      font-weight: bold;
      font-size: 1rem;
      height: 3rem;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: wrap;
    }

    i {
      position: absolute;
      top: 23%;
      right: calc(50% - 40px);
      transform: translate(-50%, -23%);

      font-size: 0.8em;
      padding: 0.2rem;
      border-radius: 50%;
      background-color: #b4ff9f;
      color: #ffffff;
      border: 1px solid #ffffff;
    }
    span {
      position: absolute;
      top: 5px;
      right: calc(30% - 45px);
      transform: translate(-50%, -23%);
      font-size: 0.8em;
      padding: 0.2rem;
      background-color: transparent;
      color: #22b573;
      border: 1px solid #22b573;
      border-radius: 50%;
    }
    // div {
    //   position: absolute;
    //   top: 3px;
    //   right: calc(30% - 55px);
    //   transform: translate(-50%, -23%);
    //   font-size: 0.8em;
    //   padding: 0.2rem;
    //   background-color: #b4ff9f;
    //   color: #ffffff;
    //   border: 1px solid #ffffff;
    //   border-radius: 50%;
    // }

    input {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      &:checked ~ i {
        background-color: #b4ff9f;
        color: #ffffff;
        border: 1px solid #ffffff;
      }
    }
  }
}
