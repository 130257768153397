.social-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // max-width: 35rem;
  // padding: 0.9375rem;
  // margin: 0 auto;
  // color: #333;
  gap: 20px;

  @media (max-width: 625px) {
    width: 400px;
  }
  img {
    border-radius: 8%;
  }
  .play-add-social {
    border-radius: 30%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 4px;
    left: 0px;
    right: 0px;
    text-align: center;
    &:hover {
      transform: scale(1.2);
    }
  }

  .play-text-add-social {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 10px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 13.5px;
    font-weight: 900;
    color: whitesmoke;
    margin: center;
  }
  .text-add-social {
    // position: absolute;
    // margin-left: auto;
    // margin-right: auto;
    // top: 140px;
    // left: 0px;
    // right: 0px;
    text-align: center;
    font-size: 15px;
    font-weight: 900;
    color: whitesmoke;
    margin: center;
  }
  .add-social-card {
    min-height: 235px;
    margin-bottom: 0px;
    width: 180px;
    max-width: 240px;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 400px) {
      width: 180px;
    }

    cursor: pointer;
    &,
    .icon,
    p {
      transition: all 0.3s ease-in-out;
    }

    .icon {
      font-size: 60px;
    }

    p {
      font-size: 26px;
      font-weight: bold;
      margin-top: 20px;
      line-height: 25px;
      word-spacing: 7px;
      text-align: start !important;
    }

    &:hover {
      .icon {
        transform: scale(1.2);
      }
      p {
        transform: scale(1.05);
      }
    }

    &:active {
      .icon,
      p {
        transform: scale(1);
      }
    }
  }
}
