.addjob-save-button {
  display: flex;
  position: fixed;
  top: 30px;
  right: 130px;
  z-index: 999;
  gap: 10px;

  @media only screen and (max-width: 500px) {
    right: 10px;
    top: 20px;
  }
}
.item-date-picker-container {
  gap: 10px;
}

.item-date-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.day-estimate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.header-word {
  margin-bottom: 5px;
}
.gender-addjob {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  :hover {
    transform: scale(1.1);
  }
  span {
    // background: green;
    height: fit-content;
    width: fit-content;
    padding: 1px 3px 1px 3px;
    border-radius: 50%;
    background-color: transparent;
    color: #22b573;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 999;
    border: 1px solid #22b573;
  }
}
.group-social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.each-social {
  display: flex;
  padding: 5px 12px 5px 5px;
  width: 100%;
  max-width: 120px;
  height: fit-content !important;
  cursor: pointer;
  transition: 0.4s ease all;
  color: white;
  font-size: 11px;
  text-align: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
}
.task-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.facebook-dialog {
  display: flex;
  position: absolute;
  width: 50px;
  height: 100px;
  background-color: antiquewhite;
}
.instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.formSocialTask {
  width: 100%;
  max-width: 1107px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 280px;
  margin-bottom: 15px;
}
.remove-social-form-button {
  cursor: pointer;
}
.data-form-each-social {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
}
.form-title {
  font-size: 14px;

  font-weight: bold;
}
.form-percent {
  font-size: 14px;
}
.disabledDiv {
  background-color: #f0f0f0; /* Example background color for disabled appearance */
  color: #999; /* Example text color for disabled appearance */
  pointer-events: none; /* Disable pointer events to prevent interaction */
  opacity: 0.6; /* Lower opacity for a disabled look */
}

.text-editer {
  height: 170px;
  // /* added these styles */
  // flex: 1;
  // display: flex;
  // flex-direction: column;
}
.editor-class.rdw-editor-main {
  background-color: white;
}
.text-center {
  background-color: white;
  border-radius: 10px;
}
button#rfs-btn {
  background-color: white;
  border-radius: 50px;
  color: black;
}
span.ReactFlagsSelect-module_label__27pw9 {
  color: black;
}
