.auth-select-role-component-container {
  display: flex;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .select-item-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    .select-item {
      margin: 0 20px;

      p {
        font-size: 18px;
        margin-top: 10px;
        text-align: center;
      }
    }
    hr {
      display: 'none';
      @media (max-width: 1060px) {
        margin: 0 0 20px 0;
        width: 50%;
      }
    }
  }
  .card-advertiser {
    height: 433px;
    width: 347px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 5px 3px #00000040;
    border: none;
  }
  .card-publisher {
    height: 433px;
    width: 347px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 5px 3px #00000040;
    border: none;
  }
  .card-publisher-back {
    height: 433px;
    width: 347px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background: black;
  }

  .card-advertiser-back {
    height: 433px;
    width: 347px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background: black;
  }

  .flip {
    position: relative;
    > .front,
    > .back {
      display: block;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 1s;
      transition-property: transform, opacity;
    }
    > .front {
      transform: rotateY(0deg);
    }
    > .back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateY(180deg);
      }
      > .back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }
  .flip-fixed {
    position: relative;
    > .front,
    > .back {
      display: block;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 1s;
      transition-property: transform, opacity;
    }
    > .front {
      transform: rotateY(0deg);
    }
    > .back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateY(180deg);
      }
      > .back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }
}

.auth-by-email-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem !important;
  @media (max-width: 768px) {
    padding: 1rem !important;
    width: 100vw;
  }
}

.register-third-party-container {
  .third-party-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .google-button {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
      border-radius: 8px !important;
      color: black !important;
      width: 200px !important;
      display: flex !important;
      justify-content: center !important;
      text-align: center !important;
      margin: 5px 5px !important;
      height: fit-content !important;
      min-height: 45px !important;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        color: black !important;
      }
    }

    .facebook-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #3b5a99;
      color: #fff;
      border: 0;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 8px;
      margin: 5px 5px;
      width: 200px;
      height: fit-content;
      min-height: 45px !important;
      padding: 10px;

      .facebook-icon {
        width: 25px;
        height: 25px;
      }
      .facebook-text {
        font-family: Roboto, sans-serif;
        margin-left: 10px;
      }
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
    .facebook-button-new {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #3b5a99
        url('../../assets/images/social/facebook/facebook-icon.svg') no-repeat left
        center; /* Adjusted path to SVG icon */
      background-size: 25px;
      color: #fff;
      background-position: left calc(12% - 10px) center;
      border: 0;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 8px;
      margin: 5px 5px;
      width: 200px;
      height: fit-content;
      min-height: 45px !important;
      padding: 10px 0px 10px 20px;
      font-family: Roboto, sans-serif;

      .facebook-text {
        margin-left: 10px;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }

  .register-line {
    overflow: hidden;
    text-align: center;
    margin-top: 10px;
  }

  .register-line:before,
  .register-line:after {
    background-color: #9b9b9b;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .register-line:before {
    right: 1em;
    margin-left: -50%;
  }

  .register-line:after {
    left: 1em;
    margin-right: -50%;
  }
}
.disable-emoji {
  display: none;
}
.disable-flip-fixed {
  position: relative;
  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 1s;
    transition-property: transform, opacity;
  }
  > .front {
    transform: rotateY(0deg);
  }
  > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }
  // &:hover {
  //   > .front {
  //     transform: rotateY(180deg);
  //   }
  //   > .back {
  //     opacity: 1;
  //     transform: rotateY(0deg);
  //   }
  // }
}
@media screen and (max-width: 420px) {
  .flip-fixed {
    position: relative;
    > .front,
    > .back {
    }
    > .front {
      transform: none !important;
    }
    > .back {
      transform: none !important;
    }

    &:hover {
      > .front {
        transform: 0 !important;
      }
      > .back {
        opacity: 0 !important;
        transform: none !important;
      }
    }
  }
}
