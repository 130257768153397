.user-page-filter-table {
  display: flex;
  justify-content: space-between;
  .role-email-container {
    display: flex;
    .search-bar {
      margin: 0 20px;
    }
  }
}
