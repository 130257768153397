.img-logo-homepage {
  @media (max-width: 1240px) {
    display: none !important;
  }
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped:wght@600&family=Niramit:wght@600&display=swap');
.font-typing {
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
  font-size: 3rem;
}
.font-typing2 {
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
  color: white;
}
.influencer-card-homepage {
  position: relative;
  // maxWidth: '425px',
  height: 200px;
  display: flex;
  justify-content: center;
  // border: '2px solid red',
  white-space: 'pre-line';
  strong {
    color: white !important;
    margin: 0;
    font-size: 16px;
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  span {
    word-break: break-all;
    line-height: 1.8;
    // position: 'absolute',
    margin: 1rem 0.5rem auto auto;
    z-index: 100;
    width: 50%;
    color: white;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: 'column';
    align-items: 'center';
    text-align: 'center';
    position: 'relative';
    // padding: '0 5px'

    @media (max-width: 450px) {
      margin: 1rem 0.5rem auto auto;
      font-size: 12px;
    }
    img {
      position: absolute;
      top: calc(100% + 20px);
      width: 30px;

      @media (max-width: 450px) {
        top: calc(100% + 20px);
        width: 25px;
      }
    }
  }
  @media (max-width: 450px) {
    height: 150px;
  }
}
.marketer-card-homepage {
  position: relative;
  // maxWidth: '425px',
  height: 200px;
  display: flex;
  justify-content: center;
  // border: '2px solid red',
  white-space: 'pre-line';

  strong {
    color: white !important;
    margin: 0;
    font-size: 16px;
    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  span {
    word-break: break-all;
    line-height: 1.8;
    // position: 'absolute',
    margin: 1rem 0.5rem auto auto;
    z-index: 100;
    width: 50%;
    color: white;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: 'column';
    align-items: 'center';
    text-align: 'center';
    position: 'relative';
    // padding: '0 5px'

    @media (max-width: 450px) {
      margin: 1rem 0.5rem auto auto;
      font-size: 12px;
    }
    h5 {
      line-height: 1.8;
      // position: 'absolute',
      z-index: 100;
      color: white;
      font-weight: bold;
      position: relative;
      // padding: '0 5px',
    }
    img {
      position: absolute;
      top: calc(100% + 20px);
      width: 30px;

      @media (max-width: 450px) {
        top: calc(100% + 20px);
        width: 25px;
      }
    }
  }

  @media (max-width: 450px) {
    height: 150px;
  }
}
.text-logo-af {
  font-size: 24px;
  font-weight: bold;
  margin: 1rem 0 4rem 0;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 1rem 0 2rem 0;
  }

  @media (max-width: 576px) {
    font-size: 16px;
  }
}
.register-login {
  display: flex;
  gap: 0.5rem;

  @media (max-width: 768px) {
    display: none;
  }
}
