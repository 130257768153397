.page-content-dashboard {
  margin-left: 5rem;
  width: 100%;
  padding: 0px;
  @media (max-width: 915px) {
    margin-left: 0rem;
  }
}

.dashboard-wraper {
  padding: 20px 20px 0px 30px;

  @media (max-width: 859px) {
    padding: 5px;
  }
  @media (max-width: 375px) {
    padding-right: 0px;
  }
  @media (max-width: 280px) {
  }
}

div#dashboard-page h4,
div#dashboard-page p {
  color: white;
}
.maketer-dashboard-card-wraper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  padding: 0 40px 0px 40px;
  flex-wrap: wrap;
  // border-bottom: #ebeef2 0.1px solid;
  padding-bottom: 40px;
  padding-left: 20px;
  @media (max-width: 859px) {
    padding: 10px;
  }
  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
}
.card-dashboard {
  color: black;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 280px;
  height: 226px;
  background: linear-gradient(
    143deg,
    rgba(223, 224, 231, 1) 0%,
    rgba(255, 255, 255, 1) 44%
  );
  border: solid 5px white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;

  @media (max-width: 658px) {
  }
}
.card-dashboard i {
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: black;
}
i.bx.bx-world {
  font-size: 2rem;
  color: black;
  margin-right: 10px;
}
.card-dashboard .title-card,
.card-dashboard .title-card p,
.card-dashboard .title-card p b {
  color: black !important;
}
.card-dashboard-ex {
  display: flex;
  padding: 40px 0px 40px 0px;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}
.title-card {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  margin-bottom: 0px;
}
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.maketer-template-card-wraper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding-left: 20px;
  // border-bottom: #ebeef2 0.1px solid;
  padding-bottom: 40px;
  @media (max-width: 859px) {
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
  @media (max-width: 384px) {
    // padding-left: 5px;
    padding-left: 5px;
  }
}
.card-template {
  width: 280px;
  height: 280px;
  color: black;
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(
    143deg,
    rgba(223, 224, 231, 1) 0%,
    rgba(255, 255, 255, 1) 44%
  );
  border: solid 5px white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;
}
.button-job {
  border-radius: 50px;
  border: 1px solid white;
  color: white;
  font-weight: 600;
  width: fit-content;
  background-color: black;
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.template-img {
  border-radius: 10px 10px 10px 10px;
}
.maketer-guide-card-wraper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;

  padding-left: 20px;
  @media (max-width: 859px) {
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
  @media (max-width: 384px) {
    // padding-left: 2px;
  }
}
.guide-template {
  padding: 10px;
  gap: 10px;
  width: 300px;
  height: 300px;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    143deg,
    rgba(223, 224, 231, 1) 0%,
    rgba(255, 255, 255, 1) 44%
  );
  border: solid 5px white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;

  // @media (max-width: 384px) {
  //   width: 200px;
  //   height: 200px;
  // }
}
.goto {
  margin-top: auto;
}
.center-text {
  display: flex;
  justify-content: center;

  height: 68px;
  overflow: hidden;
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
}
