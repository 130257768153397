.card-coin-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  height: 100%;
  width: 100%;
  margin-top: 16px;
}
.card-coin {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.00001px solid rgb(202, 198, 198);
  height: 100%;
  width: 100%;
  max-width: 190px;
  max-height: 200px;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}
.active-coin {
  color: white;
  border: none;
  background: rgb(150, 240, 191);
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(104deg, #6b99ca, #6bc6b3);
  //   border: black solid;
}

.static-color-coin {
  color: white;
  border: none;
  background: rgb(150, 240, 191);
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(104deg, #6b99ca, #6bc6b3);
}
