//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    // color: $gray-700;
    color: $white;
  }
  + .breadcrumb-item {
    &::before {
      font-family: 'Material Design Icons';
      color: $white !important;
    }
  }
}

body[data-layout-mode='dark'] {
  .breadcrumb-item > a {
    color: $gray-dark-500;
  }

  .breadcrumb-item.active {
    color: $gray-dark-400;
  }
}
