.job-wraper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: #ebeef2 0.1px solid;
  padding-bottom: 40px;
}
.job-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}
