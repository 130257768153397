//
// _helper.scss
//

.text-editor-display {
  font-family: sans-serif;
  p,
  li {
    font-size: 16px !important;
    margin-bottom: 0;
  }
  li::marker {
    font-size: 25px;
  }
  .ql-font-serif {
    font-family: serif !important;
  }
  .ql-font-monospace {
    font-family: monospace !important;
  }
  // strong {
  //   font-size: 28px;
  // }
  .ql-size-large {
    font-size: 35px;
  }
  .ql-size-huge {
    font-size: 46px;
  }
}

.border-0 {
  border: 0 !important;
}

.me-sm-rem {
  margin-right: 1.5rem !important;
}

.max-w-350 {
  max-width: 350px;
}

.w-350 {
  width: 350px;
}

.p-1-rem-horizontal {
  padding: 0 1rem;
}

.p-2-rem-horizontal {
  padding: 0 2rem;
}

.p-relative {
  position: relative;
}

.w-max-content {
  width: max-content;
}
.bg-gainsboro {
  background-color: #d5d4eb;
}

.pic-frame {
  margin: auto;
  width: 90px;
  height: 50px;
  background-color: rgba(#d5d4eb, 0.3);
  padding: 10px;
}
.pic-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bg-tomato {
  background-color: #fd625e;
}

.w-150 {
  width: 150px;
}
.w-40 {
  width: 40px;
}
.rotate-180 {
  transform: rotate(180deg);
}
.min-h-200 {
  min-height: 200px !important;
}
.d-inline {
  display: inline;
}
.ps-16 {
  padding-left: 16px;
}

.hover-cw:hover {
  color: white;
}

.hover-cp {
  color: rgba(black, 0.7);
}

.hover-cp:hover {
  color: $primary;
  text-decoration: underline !important;
}

.a-cw {
  color: white;
  text-decoration: underline !important;
}

.a-cw:hover {
  color: white;
  text-decoration: underline !important;
}

.cursor {
  cursor: pointer;
}

.fe {
  float: right;
}

.p-0 {
  padding: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d5d4eb !important;
}

.gray-border {
  border: 2px solid #d5d4eb;
}

.gray-border:hover {
  cursor: pointer;
  border: 2px solid #d5d4eb;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.gray-hover:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.ps-3 {
  padding-left: 5 !important;
}

.pe-3 {
  padding-left: 5 !important;
}

.me-auto {
  margin-right: auto;
}

.coin-sm {
  height: 15px !important;
  width: 15px !important;
}

.coin-s {
  height: 20px !important;
  width: 20px !important;
}

.coin-md {
  height: 32px !important;
  width: 32px !important;
}

.coin-xl {
  height: 48px !important;
  width: 48px !important;
}

.coin-xxl {
  height: 50px !important;
  width: 50px !important;
}

.img-lg {
  height: auto !important;
  width: 7.1875rem !important;
}

.font-size-12 {
  font-size: 0.9375rem !important;
}

.font-size-14 {
  font-size: $font-size-14 !important;
}

.font-size-16 {
  font-size: $font-size-16 !important;
}

.font-size-18 {
  font-size: $font-size-18 !important;
}

.font-size-19 {
  font-size: $font-size-19 !important;
}

.font-size-20 {
  font-size: $font-size-20 !important;
}

.font-size-21 {
  font-size: $font-size-21 !important;
}

.font-size-22 {
  font-size: $font-size-22 !important;
}

.font-size-24 {
  font-size: $font-size-24 !important;
}

.font-size-26 {
  font-size: $font-size-26 !important;
}

.font-size-28 {
  font-size: $font-size-28 !important;
}

.font-size-30 {
  font-size: $font-size-30 !important;
}

.font-size-34 {
  font-size: $font-size-34 !important;
}

.font-size-40 {
  font-size: $font-size-40 !important;
}

.font-size-50 {
  font-size: $font-size-50 !important;
}

.font-size-60 {
  font-size: $font-size-60 !important;
}

.font-size-70 {
  font-size: $font-size-70 !important;
}

.font-30-responsive {
  font-size: calc(16px + 0.972vw);
}

.font-30-responsive-2 {
  font-size: calc(24px + 0.417vw);
}

.font-34-responsive {
  font-size: calc(26px + 0.556vw);
}

.ms-20px-responsive {
  margin-left: 1.389vw;
}

.w-200px-responsive {
  width: 13.889vw;
}

.w-fit-content {
  width: fit-content;
}

.fit-content {
  width: fit-content;
  height: fit-content;
}

.image-in-card {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  padding: 0 calc(2.778vw);
}

.image-in-card-lg {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  padding: 0 calc(5.556vw);
}

.main-card {
  border: 1.5px solid #d5d4eb;
  cursor: pointer;
  margin-bottom: 0;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

.p-40px-horizontal-responsive {
  padding: 0 calc(2.778vw);
}
.p-80px-horizontal-responsive {
  padding: 0 calc(5.556vw);
}

.outline-sm {
  position: relative;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  padding: 0.25rem 1rem;
  font-size: 14px !important;
  border-radius: 0.2rem;
  color: #60aaad;
  border-color: $primary;
  &:hover {
    color: #fff;
    background-color: #6b99ca;
  }
  &.active {
    color: #fff;
    background-color: #6b99ca;
    border-color: #6b99ca;
  }
}

// 16px
.font-size-normal {
  font-size: 1rem !important;
}

// 20px
.font-size-medium {
  font-size: 1.25rem !important;
}

// 36px
.font-size-large {
  font-size: 2rem !important;
}

// 40px
.font-size-extra-large {
  font-size: 2.5rem !important;
}

// Font weight help class

.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.h-mx-150 {
  max-height: 150px;
}

.h-mx-100 {
  max-height: 100px;
}

.h-100-per {
  height: 100%;
}

.h-100-per-full {
  height: 100vh;
}
.bg-gray-full {
  background: #1a1a1a;
}

.w-90-per {
  width: 90%;
}

.w-100-per {
  width: 100%;
}

.w-40-per {
  width: 40%;
}
.w-80-per {
  width: 80%;
}

.max-w-100-per {
  max-width: 100% !important;
}

.max-h-100-per {
  max-height: 100%;
}

.w-auto {
  width: auto;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-normal {
  font-weight: normal !important;
}

// Icons Sizes
.icon-xs {
  height: 14px;
  width: 14px;
}

.icon-sm {
  height: 16px;
  width: 16px;
}

.c-w {
  color: white;
}

.icon-md {
  height: 18px;
  width: 18px;
}

.icon-lg {
  height: 20px;
  width: 20px;
}

.icon-xl {
  height: 22px;
  width: 22px;
}

.icon-xxl {
  height: 40px;
  width: 40px;
}

.icon-30 {
  height: 30px;
  width: 30px;
}

.icon-34 {
  height: auto;
  width: 2.3125rem !important;
}

// Card Height 100
.card-h-100 {
  height: calc(100% - #{$grid-gutter-width});
}

.color-primary {
  color: $primary;
}

.bg-color-cornflowerblue {
  background-color: #56acf0 !important;
  &:hover {
    background-color: #008ffc !important;
  }
}

.bg-color-primary {
  background-color: rgba($primary, 0.2);
}
// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}

.h-240 {
  min-height: 240px;
  max-width: 100%;
}

.h-290 {
  height: 290px;
  max-width: 100%;
}

.w-60 {
  width: 60px;
  max-width: 100%;
}

.w-xs {
  width: 80px;
  max-width: 100%;
}

.w-sm {
  width: 95px;
  max-width: 100%;
}

.w-md {
  width: 110px;
  max-width: 100%;
}

.w-lg {
  width: 140px;
  max-width: 100%;
}

.w-xl {
  width: 160px;
  max-width: 100%;
}

// bg overlay
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: $black;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto
      no-repeat;
  }
}

.icon-green {
  color: $mainGreen !important;
}

.icon-red {
  color: #fd625e !important;
}

.text-btc {
  color: #f2a900 !important;
}

.text-eth {
  color: #627eea !important;
}

.text-usdt {
  color: #189871 !important;
}

.text-xrp {
  color: #434c54 !important;
}

.text-wis {
  color: #007aa3 !important;
}

.text-doge {
  color: #dfa005 !important;
}

.text-advertiser {
  color: $bluePrimary !important;
}

.text-publisher {
  color: $yellowPrimary !important;
}

.text-admin {
  color: #0080fe !important;
}

.text-mkt {
  color: #fd6a02;
}
.text-influ {
  color: #4cbb17;
}
.text-admin2 {
  color: #0080fe;
}
.text-hold {
  color: #28415a;
}
.text-fee {
  color: #e3ef33;
}

.text-line-height {
  line-height: 30px;
}

.select-wrapper {
  position: relative;
  .css-26l3qy-menu {
    position: absolute;
    top: -850%;
    z-index: 2147483647;
  }
}
.css-26l3qy-menu option:hover {
  background-color: black !important;
}
select.arrow-dropdown {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.address-chain:hover {
  background-color: $primarySecondary;
  cursor: pointer;
}

.hr-color {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.bg-yellow {
  background-color: $yellowPrimary !important;
}

.bg-advertiser {
  background-color: $bluePrimary !important;
}

.bg-admin {
  background-color: #7f8387;
}

.bg-publisher {
  background-color: $yellowPrimary !important;
}

.bg-overlay-main-green {
  background-color: linear-gradient(104deg, #6b99ca, #6bc6b3) !important;
}

.bg-green {
  background-color: $primary;
}

.text-yellow {
  color: $yellowPrimary !important;
}

.text-black {
  color: $black !important;
}

.text-white {
  color: $white !important;
}
.c-pointer {
  cursor: pointer !important;
}

.text-color-primary {
  color: #495057 !important;
}
select.me-2.form-control.fit-content.text-color-primary {
  border-radius: 15px;
  background-color: black;
  color: white !important;
  text-align: center;
}

.bg-header-table {
  background-color: white !important;
}

body[data-layout-mode='dark'] {
  .bg-color-primary {
    background-color: #b7e2da;
  }

  .main-card {
    border: 1.5px solid #202020;
    cursor: pointer;
    margin-bottom: 0;
    &:hover {
      box-shadow: rgba(#7d706c, 0.9) 0px 4px 6px, rgba(#7d706c, 0.9) 0px 4px 6px,
        rgba(#7d706c, 0.9) 0px -3px 5px;
    }
  }

  .bg-color-cornflowerblue {
    background-color: #56acf0 !important;
    color: black !important;
    &:hover {
      background-color: #008ffc !important;
      color: black !important;
    }
  }

  .text-color-primary {
    color: #a3958f !important;
  }

  .hover-cp {
    color: #a3958f;
  }

  .hover-cp:hover {
    color: $primary;
    text-decoration: underline !important;
  }

  .border-color-primary {
    border-color: #202020 !important;
  }

  .bg-header-table {
    background-color: #161211 !important;
  }

  .outline-sm {
    position: relative;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    padding: 0.25rem 1rem;
    font-size: 16px !important;
    border-radius: 0.2rem;
    color: #60aaad;
    border-color: $primary;
    &:hover {
      color: #fff;
      background-color: #6b99ca;
    }
    &.active {
      color: #040500;
      background-color: #6b99ca;
      border-color: #6b99ca;
    }
  }

  .btn-close {
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width
      auto no-repeat;
  }

  // border
  .border-light {
    border-color: $gray-dark-300 !important;
  }

  .border-bottom {
    border-bottom: 1px solid $gray-dark-300 !important;
  }

  .border-top,
  .border,
  .list-group-item {
    border-color: $gray-dark-300 !important;
  }

  @each $color, $value in $theme-colors {
    .border-#{$color} {
      border-color: $value !important;
    }
  }

  // text colors
  .text-dark {
    color: $gray-dark-800 !important;
  }

  .text-muted {
    color: $gray-dark-400 !important;
  }

  .text-body {
    color: var(--bs-body-color) !important;
  }

  // List item
  .list-group-item {
    background-color: $gray-dark-200;
    color: $gray-dark-500;
  }

  // img thumbnail

  .img-thumbnail {
    background-color: lighten($gray-dark-200, 2.5%);
    border-color: $gray-dark-300;
  }

  // popover-header
  .popover-header {
    color: $gray-dark-200;
  }
}
