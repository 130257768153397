.job-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: auto;

  .card-container {
    position: relative;
    height: max-content;
    min-height: 300px;
    width: 100%;
    max-width: 50rem;
    margin-top: 24px;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;
    .icon-category {
      display: flex;
      align-items: center;
      .detail-text-label {
        font-size: 16px;
        font-weight: 400;
        color: rgb(73, 80, 87);
        word-break: break-all;
        text-align: start;

        label {
          @media screen and (max-width: 375px) {
            font-size: 14px !important;
          }
        }
      }
    }
    form {
      width: 80%;
      margin: auto;

      @media only screen and (max-width: 540px) {
        width: 100%;
      }
    }
    .form-date-container {
      width: 100%;
      max-width: 350px;
      display: flex;
      justify-content: flex-start;
      margin-left: 20px;
      margin-top: 10px;
      @media only screen and (max-width: 600px) {
        display: flow-root;
        width: 80%;
      }
    }
    .profile-container {
      text-align: center;

      .image-profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .btn-next {
      background: #3baf96;
      border-radius: 1.5rem;
      padding: 0.5rem 1rem;
      min-width: 8rem;
      font-size: 1.2em;
      font-weight: bold;
      outline: none;
      border: none;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      margin: 0;
      transition: all 0.1s ease-in-out;
      text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
        0px -5px 35px rgba(255, 255, 255, 0.3);
      color: #ffffff;

      &:hover {
        transform: scale(1.05) !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
          rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      }

      &:active {
        transform: scale(0.95) !important;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
    }
    .timeline {
      padding: 0;
      position: relative;
      max-width: 1200px;
      margin: 0;
      width: 90%;
      /* background-color: red; */

      &::after {
        content: '';
        position: absolute;
        width: 6px;
        background-color: #888585;
        top: 0;
        bottom: 0;

        margin-left: -3px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      }
    }
    .timeline-container {
      padding: 0 0 10px 0px;
      position: relative;
      background-color: inherit;
      width: 100%;
      left: 10%;

      &::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: #dddddd;
        border: 4px solid #888585;
        top: 15px;
        left: -12%;
        border-radius: 50%;
        z-index: 1;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        @media only screen and (max-width: 800px) {
          left: -13%;
        }
      }

      &::before {
        content: ' ';
        height: 0;
        position: absolute;
        top: 22px;
        width: 0;
        z-index: 1;
        left: -10px;
        border: medium solid rgba(60, 64, 67, 0.3);
        border-width: 10px 10px 10px 0;
        border-color: transparent rgba(60, 64, 67, 0.3) transparent transparent;
      }
    }
    .timeline-contents {
      padding: 0;
      position: relative;
      min-height: 120px;
      margin-bottom: 0;
      border-radius: 15px;
      box-shadow: 0px 4px 6px rgba(73, 80, 87, 0.25);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .font-date-input {
        font-size: 16px !important;

        @media screen and (max-width: 405px) {
          font-size: 0.8rem !important;
          padding: 0 5px !important;
        }

        label {
          font-size: 16px !important;
          @media screen and (max-width: 405px) {
            text-align: center;
            font-size: 0.8rem !important;
          }
        }
        span {
          @media screen and (max-width: 405px) {
            font-size: 0.8rem !important;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .timeline-contents {
        flex-direction: column;
      }
    }
    .card-date-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      box-shadow: 0px 4px 4px 0px #00000040;
      width: 203px;
      height: auto;
      background: #21603e;
      border: none;
    }
    @media only screen and (max-width: 600px) {
      .card-date-container {
        width: 100%;
      }
    }
    .card-date-container-selected {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      width: 120px;
      height: auto;
      background: white;
      border: 2px solid #21603e;
    }
    @media only screen and (max-width: 405px) {
      .card-date-container-selected {
        width: 100px;
      }
    }
    .card-date-selected-label {
      color: #21603e;
      padding: 0;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 29px;
      @media screen and (max-width: 405px) {
        font-size: 0.8rem !important;
      }
    }
    .card-date-non-selected-label {
      color: white;
      padding: 5px 13px 5px 10px;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
    }
  }
}
.progress-bar {
  background: linear-gradient(122.98deg, #82e3d6 21.41%, #a983fb 79.1%) !important;
}
.create-campaign-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .type-card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    margin-bottom: 6px;
  }

  .detail-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    margin-bottom: 6px;
  }
  .header-text {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #a983fb;
    @media screen and (max-width: 375px) {
      font-size: 18px !important;
    }
  }
  .title-head {
    font-size: 16px;
    font-weight: 600;
    // line-height: 1.2;
    color: #a983fb;
    @media screen and (max-width: 375px) {
      font-size: 14px !important;
    }
  }
  .detail-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 0.8;
    color: rgb(73, 80, 87);
    padding-bottom: 10px;
    word-break: break-all;
    text-align: start;
    @media screen and (max-width: 375px) {
      font-size: 14px !important;
    }
    p {
      @media screen and (max-width: 375px) {
        font-size: 14px !important;
      }
    }
  }
}
.header-job-title {
  margin-bottom: 10px;
  padding-left: 15px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #a983fb;
  @media screen and (max-width: 375px) {
    font-size: 18px !important;
  }
}
.crop-button {
  width: 140px;
  height: 44px;
  font-size: 18px;
  font-weight: bold;
  border: solid 3px white;
  background-color: white;

  span {
    font-weight: bold;
    color: black;
  }
  border-radius: 3rem;
  @media screen and (max-width: 450px) {
    font-size: 14px !important;
  }

  &:hover {
    box-shadow: none;
    color: white;
    border: solid 3px white;
    background-color: black;
    transition: all 0.5s ease-in-out;

    span {
      color: #fff;
      background: none;
      background-color: none;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
    }
  }

  &:active {
    transition: all 0.1s ease-in-out;
    transform: scale(0.95);
  }
}
.button-type-job {
  border-radius: 3rem;
  width: 130px;
  height: 44px;
  font-size: 18px;
  font-weight: bold;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(104deg, #6b99ca, #6bc6b3);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;

  @media screen and (max-width: 450px) {
    font-size: 16px !important;
  }
  i {
    position: absolute;
    top: 0%;
    right: -10%;
    // right: calc(50% - 40px);
    transform: translate(-50%, -23%);
    font-size: 0.8em;
    padding: 0.2rem;
    border-radius: 50%;
    background-color: #b4ff9f;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  span {
    font-weight: bold;
    background: #6b99ca;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    box-shadow: none;
    color: white;
    transition: all 0.5s ease-in-out;

    span {
      color: #fff;
      background: none;
      background-color: none;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
    }
  }
}
.btn-bg-active {
  box-shadow: none;
  color: white;
  transition: all 0.5s ease-in-out;

  span {
    color: #fff !important;
    background: none;
    background-color: none;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
  }
}
