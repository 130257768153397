.banner-format-card {
  height: 18rem;
  padding: 1.5rem 1rem !important;
  box-sizing: content-box;

  .img-container {
    height: 60%;
  }

  .desc-container {
    height: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .banner-format-card {
    height: 15rem;

    .img-container {
      height: 50%;
    }

    .desc-container {
      height: 50%;
    }
  }
}

@media (max-width: 992px) {
  .banner-format-card {
    height: 15rem;
  }
}
