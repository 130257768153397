.image-profile {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #fbfaff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.upload-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fbfaff;
  position: absolute;
  transform: translateX(-55%);
  background: #fbfaff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: #f8b400;
    border: 1px solid #f8b400;
    transform: translateX(-55%) scale(1.05);
  }

  &:active {
    color: #f8b400;
    border: 1px solid #f8b400;
    transform: translateX(-55%) scale(0.95);
  }
}
label.upload-icon.withdraw-page {
  background: black !important;
}

.upload-input {
  display: none;
}
