.aptitude-title {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.card-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: 9px;
  gap: 1;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  justify-content: center;

  @media (max-width: 500px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
}
.image-aptitude {
  width: 40%;
  height: 30%;
  border-radius: 50%;
}
.add-social-card {
  min-height: 200px;
  margin-bottom: 0px;
  width: 180px;
  max-width: 240px;
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: linear-gradient(104deg, #6b99ca, #6bc6b3);
  border-radius: 8%;
  cursor: pointer;
  position: relative;

  @media (max-width: 400px) {
    width: 180px;
  }
}
.image-title {
  width: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: bold;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon {
  cursor: pointer;
}
.mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-camaign-title {
  font-size: 20px;
}
.page-content {
  margin: 0;
  padding: 0;
}
.marketer-campaign-container-discover {
  width: 60%;
  margin: auto;
  max-width: 950.75px;

  @media (max-width: 1563px) {
    width: 62%;
  }
  @media (max-width: 1520px) {
    width: 64%;
  }
  @media (max-width: 1488px) {
    width: 67%;
  }
  @media (max-width: 1423px) {
    width: 70%;
    justify-content: center;
  }

  @media (max-width: 556px) {
    width: 100%;
    justify-content: center;
  }
}
.filter-button {
  margin-left: 16px;
  padding: 0 15px;
  box-shadow: none;
  background: linear-gradient(104deg, #6b99ca, #6bc6b3);
  height: 34px;
  color: white;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
}
.header-discover-all {
  display: flex;
  justify-content: space-between;
  // @media (max-width: 500px) {
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  //   > button {
  //     width: fit-content;
  //   }
  // }
}
.social-card {
  .logo {
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 50%;
    background-color: #ffffff;
  }
}
.logo-socials {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 35px;
  right: 45px;
  padding: 0;
  border-radius: 50%;
  background-color: #ffffff;
}
