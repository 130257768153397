@keyframes changewidth {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.new-alert {
  animation-duration: 1.5s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background: rgb(248, 249, 237);
  z-index: 999;
  border-top: 2px solid rgb(249, 222, 41);
  box-shadow: 0 2px 6px 0 rgba(116, 120, 141, 0.5);
}
