//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

// dark layout

body[data-layout-mode='dark'] {
  // pagination
  .page-link {
    background-color: $gray-dark-200;
    border-color: $gray-dark-300;
    color: $gray-dark-500;
    &:hover {
      background-color: lighten($gray-dark-200, 2%);
      color: $primary;
    }
  }
  .page-item {
    &.disabled {
      .page-link {
        color: $gray-dark-400;
        background-color: $gray-dark-200;
        border-color: $gray-dark-300;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

// [data-layout-mode='dark'] {
//   .MuiPagination-ul.css-wjh20t-MuiPagination-ul {
//     li > button {
//       color: #a3958f;
//       background-color: transparent !important;
//       border-color: #494949 !important;

//       &.Mui-selected {
//         background-color: $primary !important;
//         color: black !important;
//       }
//     }
//   }
// }
.MuiPagination-ul li > button {
  background-color: white !important;
}
.MuiPagination-ul li button.Mui-selected {
  background-color: black !important;
  color: white !important;
  border-color: white !important;
}
.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  li > button {
    color: black;
    background-color: white !important;
    border-color: white !important;

    &.Mui-selected {
      background-color: $black !important;
      color: white !important;
    }
  }
}
.MuiPagination-ul li:first-child > button {
  border-radius: 50% 4px 4px 50% !important;
  color: black !important;
  background-color: white !important;
}
.MuiPagination-ul li:last-child > button {
  border-radius: 4px 50% 50% 4px !important;
  color: black !important;
  background-color: white !important;
}
