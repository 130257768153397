// .image-container {
//   position: relative;
//   overflow: hidden;
//   width: 300px; /* Adjust this to your preferred image width */
//   height: 100px; /* Adjust this to your preferred image height */
// }

// /* Style the image */
// .image-container img {
//   width: 100%;
//   height: 100%;
//   transition: transform 0.3s; /* Add a smooth transition effect */
// }

// /* Add zoom effect on hover */
// .image-container:hover img {
//   transform: scale(1.1); /* Adjust the scale factor as needed */
// }
.hover-image-before {
  position: relative !important ;
  border-radius: 8px;
  background: #77ce6f;
  margin: 3px;
  overflow: hidden;
  height: 400px;
  padding: 8px;
  cursor: pointer;
  :hover {
    width: 800px;
    height: 800px;
  }
}
.hover-image-after {
  position: relative !important ;
  border-radius: 8px;
  background: #77ce6f;
  margin: 3px;
  overflow: hidden;
  height: 800px;
  padding: 8px;
  cursor: pointer;
}
