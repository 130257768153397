.gleam-margin {
  margin: 16px 0px;
}

.gleam-job-timeline-container {
  // border-radius: 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
  // border: 2px #e9edee solid;
  // background-color: rgb(83, 198, 183);
  // padding: 16px;
}

.gleam-job-timeline-container-influencer {
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 2px #e9edee solid;
  background-color: rgb(83, 198, 183);
  padding: 16px;
}

.gleam-job-timeline-panel {
  max-width: 600px;
  border-radius: 6px;
  border: 2px #e9edee solid;
  // background-color: rgb(83, 198, 183);
  background-color: black;
  padding: 16px;
  display: block;
  margin: 0 auto;
}

.gleam-job-timeline-header {
  display: flex;
  justify-content: space-between;
}

.gleam-job-timeline {
  border-radius: 6px;
  width: 100%;
  margin-top: 13px;
  padding: 16px;
  background-color: white;
}

.gleam-job-component {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.gleam-job-panel {
  max-width: 600px;
  background-color: white;
  border-radius: 6px;
  display: block;
  margin: 0 auto;
  border: 1px #e9e9ef solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

  .gleam-job-header {
    border-bottom: 5px solid;
    text-align: center;
  }

  .gleam-job-name {
    padding: 16px;
    text-align: center;
  }

  .gleam-work-order {
    padding: 16px;
  }

  .gleam-work-order-detail {
    border: 1px solid #e9e9ef;
    border-radius: 6px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 16px;
  }
}

.gleam-job-task-container {
  padding: 0px 16px 7px 16px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0px 8px 7px 8px;
  }
}

.gleam-job-task-header {
  padding: 4px;
  background-color: #e9e9ef;
  border-radius: 6px;
  min-height: 55px;
}

.gleam-job-task-header-open {
  padding: 5px;
  background-color: #e9e9ef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-height: 55px;
}
.gleam-job-task-header-adv {
  padding: 4px;
  background-color: #e9e9ef;
  border-radius: 6px;
  height: 52px;

  @media (max-width: 555px) {
    height: 80px;
  }
}

.gleam-job-task-header-open-adv {
  padding: 5px;
  background-color: #e9e9ef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 52px;

  @media (max-width: 555px) {
    height: 65px;
  }
  @media (max-width: 446px) {
    height: 80px;
  }
}

.col-v-center {
  display: flex;
  align-items: center;
}
.col-end {
  justify-content: end;
}

.gleam-job-task-detail {
  padding: 16px;
  border: 1px solid #e9e9ef;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  color: white;
  background-color: black;
  @media (max-width: 768px) {
    padding: 2px;
  }
}

.campaign-name-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #495057;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  padding-left: 6px;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
  }
}
.review-post {
  background: rgb(238, 237, 237);
  width: 100px;
  padding: 5px 15px 5px 15px;
  color: black !important;
  margin-bottom: -16px;
  border-radius: 5px 5px 0px 0px;
}
.review-post-influ {
  background: rgb(238, 237, 237);
  width: 135px;
  padding: 5px 15px 5px 15px;
  color: black !important;
  margin-bottom: -16px;
  border-radius: 5px 5px 0px 0px;
}

.gleam-job-percent {
  padding: 7px;
  background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
}

.gleam-job-percent-black {
  padding: 7px;
  background-color: black;
  color: white;
  border-radius: 6px;
  font-size: 12px;
}

.gleam-job-status {
  padding: 7px;
  background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
  overflow: hidden;
  max-width: 120px;
}
.gleam-job-status-new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 13px;
  background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
  min-width: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.gleam-job-status-new-advertiser {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
  min-width: 80px;
  overflow: hidden;
  display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
}
.gleam-job-percent-new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
  margin-right: 5px;
  @media (max-width: 625px) {
    margin-right: 0px;
  }
}

.col-center {
  text-align: center;
}

.g-icon {
  padding: 16px 16px 16px 0px;
}

.g-influencer-image-container {
  display: flex;
  justify-content: center;
  .image-control {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 100px;
    flex-wrap: wrap;
    word-break: break-all;
    text-align: center;

    .radio-control {
      position: relative;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;

      &:hover {
        transform: scale(1.1);
        // border-radius: 1rem;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }

      &:active {
        transform: scale(0.95);
      }
    }
    .logo-socials {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 30px;
      right: 50px;
      padding: 0;
      border-radius: 50%;
      background-color: #ffffff;
    }

    .social-checked {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 7px;
      right: 23px;
      background-color: rgb(83, 198, 183);
      border-radius: 50%;
    }

    .g-image-title {
      //width: 150px;
      display: flex;
      justify-content: center;
      text-align: center;
      // color: white;
      font-weight: normal;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin: 10px 10px;
      border: 1px solid #e9e9ef;
    }
  }
}
.icon-down {
  @media (max-width: 768px) {
    padding: 0 0 0 2px;
  }
}

.gleam-jobs-task-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 370px) {
    flex-direction: column;
  }
}

.gleam-jobs-task-header-adver {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 446px) {
    flex-direction: column;
  }
}

.gleam-jobs-task-sub-header {
  display: flex;
  flex-direction: row;
  width: 250px;
  @media (max-width: 370px) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}
.gleam-jobs-task-sub-header-right {
  display: flex;
  flex-direction: row;
  width: 250px;
  @media (max-width: 370px) {
    justify-content: center;
  }
}
.gleam-jobs-task-sub-header-left {
  display: flex;
  flex-direction: row;
  @media (max-width: 370px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
}
.gleam-jobs-task-report-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 13px;
  // background-color: rgb(83, 198, 183);
  color: white;
  border-radius: 6px;
  font-size: 12px;
  min-width: 80px;
  overflow: hidden;
  border: none;
  cursor: pointer;
}
.hover-request-btn:hover {
  cursor: pointer;
  border: 1px solid white !important;
  background-color: black !important;
  color: white !important;
}
