.approval-status {
  .btn-pending {
    margin: auto;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    background: white;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #ffed01;
      color: #ffffff;
      margin-right: 0.4rem;
    }

    &:active {
      transform: scale(1);
    }
  }
  .btn-approved {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3.125rem;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    background: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #79b9ab;
      color: #ffffff;
      margin-right: 0.4rem;
    }

    &:active {
      transform: scale(1);
    }
  }
  .btn-rejected {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3.125rem;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    background: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    height: auto;
    transition: all 0.2s ease-in-out;

    > i {
      font-size: 0.5em;
      padding: 0.3rem;
      border-radius: 50%;
      background-color: #d10000;
      color: #ffffff;
      margin-right: 0.4rem;
    }

    &:active {
      transform: scale(1);
    }
  }
}
