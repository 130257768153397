//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  background-color: $black;
}
.card-new {
  margin-bottom: $grid-gutter-width;
  background-color: white !important;
}

.card-header {
  padding: 0 !important;
}

.card-header-padding {
  padding: 10px 10px 10px 15px !important;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15.4px;
  margin: 0 0 7px 0;
  color: #495057;
  font-weight: 600;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 0;
  font-size: $font-size-14;
}

.card-header-tabs {
  margin-top: -$card-cap-padding-y;
}

.card-header-pills {
  margin: -$card-cap-padding-y / 2;
}

.card-hoverable:hover {
  cursor: pointer;
  border: 1px solid #7a7fdc;
  background: transparent;

  .card-body {
    div {
      i {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
      }
    }
  }
}

.card-hover {
  cursor: pointer;
  border: 1px solid #d5d4eb;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

body[data-layout-mode='dark'] {
  .card,
  .card-header,
  .modal-content,
  .offcanvas {
    background-color: $gray-dark-200;
    border-color: lighten($gray-dark-200, 4%);
  }

  .card-title-desc {
    color: $gray-dark-400;
  }

  .card-title {
    color: $gray-dark-500;
  }

  .card-hover {
    cursor: pointer;
    border: 1px solid #040500;
    box-shadow: rgba(#7d706c, 0.9) 0px 4px 6px, rgba(#7d706c, 0.9) 0px 4px 6px,
      rgba(#7d706c, 0.9) 0px -3px 5px;
  }

  .card-header-text {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .card-body-website {
    height: 70px;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0 3px 3px 3px;
    border-color: #2a2a14;
    border-style: solid;
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
}

.card-campaign {
  width: 85%;
  margin: auto;
  border: 20px solid #ddf1e7;
  padding: 10px;
}

// Publisher
.card-info {
  border: 2px solid #d6d4ea;
}

.card-bg {
  background-color: #009a78;
  border-radius: 10px 10px 0 0;
  padding: 1rem 1.5rem;
  //height: 50%;
  // border: 3px solid #009a78;
}

.card-header-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}

.card-body-info {
  border-radius: 10px;
  padding: 0.5rem;
  height: 100%;
}

.card-text {
  font-size: 16px;
  font-weight: bold;
}

.card-content {
  font-size: 14px;
}

.card-body-website {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 1.25rem;
  border: 0 3px 3px 3px;
  border-color: #d6d4ea;
  border-style: solid;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  height: 65%;
}

.card-register {
  cursor: pointer;
  transition: 0.3s;
  padding: 15px 80px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 560px) {
    padding: 15px 10px;
  }

  &--publisher {
    background-color: $white !important;
    border-color: $yellowPrimary !important;
    color: $yellowPrimary !important;

    &:hover {
      background-color: $yellowPrimary !important;
      color: $white !important;
    }
  }

  &--advertiser {
    background-color: $white !important;
    border-color: $bluePrimary !important;
    color: $bluePrimary !important;

    &:hover {
      background-color: $bluePrimary !important;
      color: $white !important;
    }
  }

  &:hover {
    border-color: transparent;
    //filter: brightness(0.8);
    box-shadow: 4px 8px 6px 0 rgba(0, 0, 0, 0.5);
  }
}
