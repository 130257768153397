.account-table {
  th {
    background-color: white !important;
    border-radius: 50px 0 0 50px;
    padding: 10px;
  }
  tr {
    border-color: transparent !important;
  }
  td {
    padding: 0 !important;
  }
  span {
    color: black;
    background-color: white;
    padding: 10px 15px 10px 15px;
    border-radius: 0 50px 50px 0;
    margin-left: 2px;
  }
}
