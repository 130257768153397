.circle-select-role {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px 30px 10px 30px;
  background-color: black;
}
.circle-select-role h4 {
  color: white;
}
.deselect {
  width: 200px;
  height: 100px;
  border-radius: 8px;
  border: 5px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.register-line {
  overflow: hidden;
  text-align: center;
  margin-top: 10px;
}

.register-line:before,
.register-line:after {
  background-color: #9b9b9b;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.register-line:before {
  right: 1em;
  margin-left: -50%;
}

.register-line:after {
  left: 1em;
  margin-right: -50%;
}
