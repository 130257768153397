.float-date-input-container {
  position: relative;
  margin-top: 2.5rem;
  width: 100%;
  // height: 70px;

  $placeHolder: #000000;
  $borderColor: #a2b5bb;
  $borderInvalidColor: #eb1d36;
  .transition {
    transition: all 0.25s cubic-bezier(0.53, 0.01, 0.35, 1.5);
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .date-label {
    top: -40px;
    left: -5px;
    font-size: 1.3em;
    font-weight: bold;
    transform: scale(0.8);
  }

  label {
    @extend .transition;
    display: inline;
    transform-origin: left center;
    color: $placeHolder;
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 1.2em;
    box-sizing: border-box;
    padding: 10px 15px;
    display: block;
    position: absolute;
    top: 40px;
    left: 10px;
    z-index: 2;
    pointer-events: none;
  }

  input {
    @extend .transition;
    appearance: none;
    background-color: none !important;
    border: 1px solid $borderColor;
    line-height: 0;
    font-size: 17px;
    width: 100%;
    min-height: 46px;
    display: block;
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 5px;
    color: $placeHolder;
    font-weight: 100;
    letter-spacing: 0.01em;
    position: relative;
  }

  input {
    &:focus {
      outline: none;
      color: $placeHolder;
    }

    &:focus ~ label,
    &:not([value='']) ~ label,
    &:not([value='']):focus ~ label {
      top: -45%;
      left: -5px;
      font-size: 1.3em;
      font-weight: bold;
      transform: scale(0.8) translateY(-45%);
    }

    &:not([value='']).is-invalid ~ label,
    &:not([value='']):focus.is-invalid ~ label {
      top: -35%;
      left: -5px;
      font-size: 1.3em;
      font-weight: bold;
      transform: scale(0.8) translateY(-35%);
    }

    &[value='']:not(:focus) ~ label,
    &[value='']:focus ~ label {
      margin: 0;
    }

    &[value='']:not(:focus) ~ label {
      font-size: 1.2em;
      font-weight: normal;
      left: -5px;
      top: 10%;
      transform: translateY(-10%);
    }

    &[value='']:focus ~ label {
      font-size: 1.3em;
      font-weight: bold;
      left: 0px;
      top: -35%;
      transform: translateY(-35%);
    }

    &.is-invalid {
      border: 1px solid $borderInvalidColor !important;
    }

    &.is-invalid ~ label {
      color: red;
    }
  }

  .invalid-message {
    text-align: start;
    font-size: 0.875rem !important;
  }
}
