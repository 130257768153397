.marketer-create-campaign-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: auto;

  .card-container {
    position: relative;
    height: max-content;
    min-height: 300px;
    width: 100%;
    max-width: 50rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;

    form {
      width: 80%;
      margin: auto;

      @media only screen and (max-width: 540px) {
        width: 100%;
      }
    }

    .category-card {
      padding: 1rem;
      margin-top: 10px;
      border-radius: 1rem;
      background: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

      p:nth-child(2),
      p:nth-child(3) {
        font-size: 1rem;
        font-weight: 500;
      }

      .icon-radio-container {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        justify-items: center;

        @media only screen and (max-width: 540px) {
          grid-template-columns: repeat(2, 1fr);
        }
        &.border-left {
          position: relative;
          margin-left: 1.5rem;
          padding-left: 1.5rem;

          &::after {
            content: '';
            position: absolute;
            border-left: 1px solid #000000;
            top: 50%;
            left: 0%;
            height: 50%;
            margin-top: auto;
            margin-bottom: auto;
            transform: translateY(-50%);
          }
        }
      }
    }

    .profile-container {
      text-align: center;

      .image-profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .btn-next {
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #ffcc78, #34ec7a);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 1.5rem;
      padding: 0.5rem 1rem;
      min-width: 8rem;
      font-size: 1.2em;
      font-weight: bold;
      // outline: none;
      border: solid 3px transparent;
      box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;
      color: #1c8326;;
     

      &:hover {
        box-shadow: none;
        color: white;
        transition: all 0.5s ease-in-out;
      }

      &:active {
        transform: scale(0.95) !important;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      }
    }
  }
}
