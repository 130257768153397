.manage-influencer-page-container {
  .social-rank-card {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: #fbfaff;
    margin-bottom: 3rem;
    padding: 1rem 1.5rem;
    border-radius: 5px;

    .custom-rate-title-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .title-text {
        font-size: 25px;
        font-weight: 700;
      }

      .default-equation-container {
        display: flex;
        p {
          margin: 0 10px;
          padding: 0;
          font-size: 20px;
        }
        img {
          height: 30px;
        }
      }
    }

    .rate-by-social-items {
      display: flex;
      align-content: space-around;
      flex-wrap: wrap;

      .edit-group-control-rank {
        display: flex;
        width: 230px;
        align-items: center;
        justify-content: center;
        border: solid 2px rgb(119, 116, 116);
        padding: 10px;
        border-radius: 10px;
        margin: 20px 10px;
        flex-wrap: wrap;

        img {
          width: 200px;
        }
        input {
          margin: 20px 0;
        }

        p {
          font-size: 20px;
          word-wrap: break-word;
          margin: 0;
        }

        .btn-group-control-rank {
          display: flex;
          align-items: center;
          margin: 10px;

          .text-value {
            font-size: 30px;
            padding: 0 30px;
          }
          .btn-minus {
            background-color: #d61d1dba;
            color: white;
            box-shadow: 0 2px 6px 0 rgba(116, 120, 141, 0.5);
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
          .btn-plus {
            background-color: #1dd633ba;
            color: white;
            box-shadow: 0 2px 6px 0 rgba(116, 120, 141, 0.5);
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
        }
      }
      .tooltip-price-rate {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
      }
    }
    .search-sample-social {
      display: flex;
      align-content: space-around;
      flex-wrap: wrap;

      .edit-group-control-rank {
        display: inline-block;
        align-items: center;
        justify-content: center;
        border: solid 2px rgb(119, 116, 116);
        padding: 10px;
        border-radius: 10px;
        margin: 20px 10px;

        img {
          width: 200px;
        }
        input {
          margin: 20px 0;
        }

        p {
          font-size: 20px;
          word-wrap: break-word;
          margin: 0;
        }

        .btn-group-control-rank {
          display: flex;
          align-items: center;
          margin: 10px;

          .text-value {
            font-size: 30px;
            padding: 0 30px;
          }
          .btn-minus {
            background-color: #d61d1dba;
            color: white;
            box-shadow: 0 2px 6px 0 rgba(116, 120, 141, 0.5);
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
          .btn-plus {
            background-color: #1dd633ba;
            color: white;
            box-shadow: 0 2px 6px 0 rgba(116, 120, 141, 0.5);
            border: none;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
        }
      }
      .tooltip-price-rate {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
      }
    }
    .submit-social-rate {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
    }
  }

  .media-type-multiple-container {
    display: flex;
    margin: 0;

    input {
      height: 30px;
    }
    .media-type-multiple {
      display: flex;
      padding: 0 20px;
      p {
        font-size: 20px;
        padding: 0 10px;
        margin: 0;
      }
    }
  }

  .price-rate-container {
    box-shadow: rgba(129, 127, 127, 0.16) 0px 1px 4px,
      rgb(180, 176, 176) 0px 0px 0px 3px;
    border-radius: 20px;
    padding: 10px 20px;
    margin-top: 20px;
    .engagement-input-container {
      display: flex;
      input {
        margin: 0 10px;
        height: 30px;
      }
      p {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}
