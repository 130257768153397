//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn-width-medium {
  min-width: 80px;
}

.btn-invisible {
  padding: 0;
  border: none;
  background: none;
}

.btn-submit {
  background-color: $primary;
  min-width: 300px;
  height: 70px;
  font-size: 24px;
  font-weight: bold;
}

.btn-info {
  // width: 100%;
  // height: 100%;
  font-size: 14px;
  // border-radius: 10px;
}

.btn-xs {
  width: 40px;
  height: 40px;
}

.btn-md {
  width: 100px;
  height: 40px;
}

.btn-lg-2 {
  width: 180px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.btn-lg-1 {
  width: 150px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.back-btn {
  cursor: pointer;

  &:hover {
    color: $blue !important;
  }
}

@mixin button-shadow($color) {
  box-shadow: 0 2px 6px 0 rgba($color, 0.5);
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

.btn-warning {
  color: $white !important;
}

.btn-outline-light {
  color: $dark;
}
//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.btn-soft-light {
  color: $dark;
  background-color: rgba($light, 0.25);

  &:hover,
  &:focus,
  &:active {
    color: $dark;
  }
}

//
// Split Dropdown buttons
//

@mixin button-variant-split($bg) {
  background-color: lighten($bg, 5%);
  border: none;
}

@each $color, $value in $theme-colors {
  .btn-#{$color}.dropdown-toggle-split {
    @include button-variant-split($value);
  }
}

// Rounded Buttons
.btn-rounded {
  border-radius: 0px;
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: 'OR';
      position: absolute;
      font-size: 10px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  border: none;

  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.15);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
    }
  }

  &.btn-soft-light {
    .label-icon {
      background-color: rgba($dark, 0.05);
    }
  }
}

.btn-label-right {
  position: relative;
  padding-right: 44px;
  background-color: #c1c0ff;
  border: 2px solid #9479f2;
  color: rgb(0, 0, 0);

  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: rgba($white, 0.15);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
    }
  }

  &.btn-soft-light {
    .label-icon {
      background-color: rgba($dark, 0.05);
    }
  }
}

body[data-layout-mode='dark'] {
  // buttons
  .btn-light {
    color: $gray-dark-500;
    background-color: $gray-dark-300;
    border-color: $gray-dark-300 !important;
    box-shadow: 0 2px 6px 0 rgba($gray-dark-300, 0.5);
  }

  .btn-outline-light {
    color: $gray-dark-500;
    border-color: $gray-dark-300;

    &:hover {
      color: $gray-dark-500;
      background-color: $gray-dark-300;
      border-color: $gray-dark-300;
    }
  }

  .btn-check:focus + .btn-light,
  .btn-light:focus,
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($gray-dark-300, 0.5);
  }

  .btn-shortcut-range {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 1rem;
    background-color: transparent;
    border: 1px solid rgba(211, 211, 211, 0.25);
    border-radius: 10px;
    color: #ffffff;

    &:hover,
    &:focus,
    &.active {
      transition: 0.2s;
      color: #3b4ccd;
      background-color: #e8f0fd;
    }
  }

  .btn-check-icon {
    fill: #3b4ccd;
    // margin-top: 0.125rem;
  }

  .btn-submit-size {
    border-radius: 10px;
    margin: 30px 0 0 0;
    background-color: $primary;
    min-width: 200px;
    height: 70px;
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
}

.btn-publisher {
  background-color: $white !important;
  color: $black;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  min-width: 10rem !important;
  border: solid 3px white;
  border-radius: 15px;
  &:hover {
    color: $white !important;
    background-color: $black !important;
    border: solid 3px white;
  }
}

.btn-advertiser {
  background-color: $black !important;
  border-color: $white !important;
  color: $white;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  border: solid 3px;
  min-width: 10rem !important;
  border-radius: 15px;
  &:hover {
    color: $black !important;
    background-color: $white !important;
  }
}

.btn-outline-light {
  &:hover {
    color: $white !important;
  }
}

.btn-blue {
  background-color: $mainBlue !important;
  color: $white !important;
}

.btn-shortcut-range {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 1rem;
  background-color: #f8f8f8;
  border: 1px solid #e9e9e9;
  border-radius: 10px;

  &:hover,
  &:focus {
    transition: 0.2s;
    color: #3b4ccd;
    background-color: #e8f0fd;
  }

  &.active {
    transition: 0.2s;
    color: #3b4ccd;
    background-color: #e8f0fd;
  }
}

.btn-check-icon {
  fill: #3b4ccd;
}

.btn-submit-size {
  border-radius: 10px;
  margin: 30px 0 0 0;
  background-color: $primary;
  min-width: 200px;
  height: 70px;
  font-size: 24px;
  font-weight: bold;
}

.btn-white {
  background-color: #fff;
  color: $primary;
}

.btn-black {
  background-color: #ffffff;
  color: $black;
  border-radius: 15px;
  &:hover {
    color: $white !important;
    background-color: black;
    border: solid 1px #fff;
  }
}

.btn-outline-rounded {
  border-radius: 15px;
  &:hover {
    color: black !important;
    background-color: $white !important;
  }
}
.btn-language {
  border: solid 3px white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-top: 5px;
}
.btn-dropdown-selected {
  border-radius: 0 50% 50% 0 !important;
  width: 40px;
  height: 40px;
}
.btn-register-unsuccess {
  border-radius: 50px !important;
}
.btn-register-success {
  border-radius: 50px !important;
  background-color: black !important;
  box-shadow: none !important;
  border: none !important;
}
